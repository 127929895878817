import React, { Component } from "react";
import { Button, Modal, Col } from "antd";
import Select from "react-select";

class OtherFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      data: {
        phone: this.props.phoneFilters,
        companyName: this.props.companyNameFilters,
        name: this.props.nameFilters,
        mode: this.props.modeFilters,
        status: this.props.statusFilters,
        loader: true,
      },
    };
  }

  handleOk = () => {
    this.props.filterChange(this.state.data);
    this.props.filterOnClick();
  };

  handleReset = () => {
    let data = {
      phone: [],
      name: [],
      companyName: [],
      mode: [],
    };
    this.props.filterChange(data);
    this.props.filterOnClick();
  };

  handleSelectChange = (name, value) => {
    let { data } = this.state;
    data[name] = value;
    this.setState({
      data,
      loading: false,
    });
  };

  handleCancel = () => {
    this.props.filterOnClick();
  };

  render() {
    return (
      <React.Fragment>
        {this.state.visible && (
          <Modal
            centered
            title="Filter Values"
            visible={this.state.visible}
            onCancel={this.handleCancel}
            bodyStyle={{
              height: "16rem",
              overflow: "auto",
            }}
            maskClosable={false}
            footer={[
              <Button
                key="submit"
                type="primary"
                onClick={this.handleReset}
                style={{
                  background: "#3b8bf7",
                  color: "white",
                  borderColor: "#3b8bf72",
                }}
              >
                Reset
              </Button>,
              <Button
                key="back"
                onClick={this.handleOk}
                style={{
                  background: "#3b8bf7",
                  color: "white",
                  borderColor: "#3b8bf7",
                }}
              >
                Apply
              </Button>,
            ]}
            width="50%"
            style={{
              width: "50%",
            }}
          >
            <Col xs={24} sm={24} md={24} style={{ padding: "0px 10px" }}>
              <p
                style={{
                  fontSize: 11,
                  margin: "-12px 0 0.3em",
                  color: "rgba(0, 0, 0, 0.54)",
                }}
              >
                Phone
              </p>
              <Select
                isMulti
                value={this.state.data.phone}
                options={this.props.phoneFilter}
                onChange={(value) => {
                  this.handleSelectChange("phone", value);
                }}
                closeMenuOnSelect={false}
                isClearable={false}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              />
            </Col>
            <Col xs={24} sm={24} md={24} style={{ padding: "0px 10px" }}>
              <div
                style={{
                  margin: "-12px 0 -12px 0",
                  padding: "25px 0 25px",
                }}
              >
                <p
                  style={{
                    fontSize: 11,
                    margin: "0.1em 0 -0.7em",
                    color: "rgba(0, 0, 0, 0.54)",
                    padding: "1px 0 15px 0",
                  }}
                >
                  Name
                </p>
                <Select
                  style={{ margin: "-6px 0px -6px 0px" }}
                  isMulti
                  value={this.state.data.name}
                  options={this.props.nameFilter}
                  onChange={(value) => {
                    this.handleSelectChange("name", value);
                  }}
                  closeMenuOnSelect={false}
                  isClearable={false}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                />
              </div>
            </Col>
            {this.props.companyNameFilter && (
              <Col xs={24} sm={24} md={24} style={{ padding: "0px 10px" }}>
                <div
                  style={{
                    margin: "-12px 0 -12px 0",
                    padding: "25px 0 25px",
                  }}
                >
                  <p
                    style={{
                      fontSize: 11,
                      margin: "0.1em 0 -0.7em",
                      color: "rgba(0, 0, 0, 0.54)",
                      padding: "1px 0 15px 0",
                    }}
                  >
                    Company Name
                  </p>
                  <Select
                    style={{ margin: "-6px 0px -6px 0px" }}
                    isMulti
                    value={this.state.data.companyName}
                    options={this.props.companyNameFilter}
                    onChange={(value) => {
                      this.handleSelectChange("companyName", value);
                    }}
                    closeMenuOnSelect={false}
                    isClearable={false}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                </div>
              </Col>
            )}
            {this.props.modeFilter && (
              <Col xs={24} sm={24} md={24} style={{ padding: "0px 10px" }}>
                <div
                  style={{
                    margin: "-12px 0 -12px 0",
                    padding: "25px 0 25px",
                  }}
                >
                  <p
                    style={{
                      fontSize: 11,
                      margin: "0.1em 0 -0.7em",
                      color: "rgba(0, 0, 0, 0.54)",
                      padding: "1px 0 15px 0",
                    }}
                  >
                    Entry Mode
                  </p>
                  <Select
                    style={{ margin: "-6px 0px -6px 0px" }}
                    isMulti
                    value={this.state.data.mode}
                    options={this.props.modeFilter}
                    onChange={(value) => {
                      this.handleSelectChange("mode", value);
                    }}
                    closeMenuOnSelect={false}
                    isClearable={false}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                </div>
              </Col>
            )}
            {this.props.statusFilter && (
              <Col xs={24} sm={24} md={24} style={{ padding: "0px 10px" }}>
                <div
                  style={{
                    margin: "-12px 0 -12px 0",
                    padding: "25px 0 25px",
                  }}
                >
                  <p
                    style={{
                      fontSize: 11,
                      margin: "0.1em 0 -0.7em",
                      color: "rgba(0, 0, 0, 0.54)",
                      padding: "1px 0 15px 0",
                    }}
                  >
                    Status
                  </p>
                  <Select
                    style={{ margin: "-6px 0px -6px 0px" }}
                    isMulti
                    value={this.state.data.status}
                    options={this.props.statusFilter}
                    onChange={(value) => {
                      this.handleSelectChange("status", value);
                    }}
                    closeMenuOnSelect={false}
                    isClearable={false}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                </div>
              </Col>
            )}
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

export default OtherFilters;
