import React from "react";
import propTypes from "prop-types";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import AsyncCreatableSelect from "react-select/async-creatable";

const Singleselect = (props) => {
  const {
    async,
    allowCreate,
    name,
    selected,
    options,
    dataPromise,
    handleChange,
    heading,
    isLoading,
    handleCreate,
    isDisabled,
  } = props;
  const loadOptions = (input) =>
    new Promise((resolve) => {
      resolve(dataPromise(input));
    });
  return (
    <React.Fragment>
      <p
        style={{
          fontSize: 10,
          margin: "-0.1em 0 0.3em",
          color: "rgba(0, 0, 0, 0.54)",
        }}
      >
        {heading}
      </p>
      {async ? (
        allowCreate ? (
          <AsyncCreatableSelect
            cacheOptions
            defaultOptions
            value={selected}
            loadOptions={loadOptions}
            onChange={(value) => handleChange({ target: { value, name } })}
            onCreateOption={handleCreate}
            isLoading={isLoading}
            isDisabled={isDisabled}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          />
        ) : (
          <AsyncSelect
            cacheOptions
            defaultOptions
            isClearable
            value={selected}
            loadOptions={loadOptions}
            onChange={(value) => handleChange({ target: { value, name } })}
            isDisabled={isDisabled}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          />
        )
      ) : allowCreate ? (
        <CreatableSelect
          onChange={(value) => handleChange({ target: { value, name } })}
          onCreateOption={handleCreate}
          isLoading={isLoading}
          options={options}
          value={selected}
          isDisabled={isDisabled}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        />
      ) : (
        <Select
          isClearable
          value={selected}
          options={options}
          onChange={(value) => handleChange({ target: { value, name: name } })}
          isDisabled={isDisabled}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        />
      )}
    </React.Fragment>
  );
};

Singleselect.propTypes = {
  async: propTypes.bool,
  allowCreate: propTypes.bool,
  selected: propTypes.object,
  options: propTypes.array,
  dataPromise: propTypes.func,
  handleChange: propTypes.func.isRequired,
  heading: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
  isLoading: propTypes.bool,
  handleCreate: propTypes.func,
  isDisabled: propTypes.func,
};

Singleselect.defaultProps = {
  async: false,
  allowCreate: false,
  isLoading: false,
  selected: [],
  isDisabled: false,
};

export default Singleselect;
