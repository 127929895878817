import makeRequest from ".";

function generateParams(params) {
  let time = "";
  let search = "";
  let entryMode = "";
  let phone = "";
  let empName = "";
  let Name = "";

  if (params.fromTime && params.toTime) {
    time = `from_time=${params.fromTime}&to_time=${params.toTime}`;
  }

  if (params.search) {
    search = `&query=${params.search}`;
  }

  if (params.phone) {
    phone = params.phone.map((value) => `&phone[]=${value.value}`).join("");
  }

  if (params.empName) {
    empName = params.empName
      .map((value) => `&employee_name[]=${value.value}`)
      .join("");
  }

  if (params.name) {
    Name = params.name.map((value) => `&name[]=${value.value}`).join("");
  }

  if (params.entryMode) {
    entryMode = params.entryMode
      .map((value) => `&entry_mode[]=${value.value}`)
      .join("");
  }

  return `${time}${search}${entryMode}${Name}${empName}${phone}&page=${params.page ||
    1}&per_page=${params.perPage || 25}&order=created_at desc`;
}

export function fetchEmployeesData(requestParams) {
  return makeRequest({
    uri: `/api/v1/employees?${generateParams(requestParams)}`,
  });
}

export function fetchEmployeesEntry(requestParams) {
  return makeRequest({
    uri: `/api/v1/employee_entries?${generateParams(requestParams)}`,
  });
}

export function createEmployeesData(requestBody) {
  return makeRequest({
    uri: "/api/v1/employees",
    method: "POST",
    body: requestBody,
  });
}

export function updateEmployeesData(requestBody, id) {
  return makeRequest({
    uri: `/api/v1/employees/${id}`,
    method: "PUT",
    body: requestBody,
  });
}

export function getEmployeesData(phone) {
  return makeRequest({
    uri: `/api/v1/employees/${phone}`,
  });
}

export function removeEmployeesFace(id) {
  return makeRequest({
    uri: `/api/v1/employees/${id}/remove_face`,
    method: "PUT",
  });
}

export function aggregation(requestParams) {
  return makeRequest({
    uri: `/api/v1/employee_entries/aggs?${generateParams(requestParams)}`,
  });
}


export function EmpAggregation(requestParams) {
  return makeRequest({
    uri: `/api/v1/employees/aggs?${generateParams(requestParams)}`,
  });
}
