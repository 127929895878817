import React, { Component } from "react";
import Form from "./Form";
import { updateVizitin, createVizitin } from "api/vizitin";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import DialogForm from "hoc/dialogForm";
class CRUD extends Component {
  constructor(props) {
    super(props);
    this.id = undefined;
    this.timer = null;
    this.state = {
      data: {
        name: "",
        mac_address: "",
        device_type: this.props.data?.device_type || "vizitin_plus",
        status: "active",
        bots_data: [],
        selected_bots: "",
        timeout: "180",
      },
      open: false,
    };
  }

  componentDidMount = () => {
    if (this.props.data) {
      const {
        id,
        name,
        device_type,
        mac_address,
        type,
        status,
        timeout,
      } = this.props.data;
      this.id = id;
      this.setState({
        data: {
          ...this.state.data,
          device_type,
          name,
          mac_address,
          type,
          status,
          timeout,
        },
        id,
      });
    }
  };

  handleChange = (e) => {
    const { data } = this.state;
    data[e.target.id] = e.target.value;
    this.setState({ data });
  };

  multiselectChange = (e) => {
    const { data } = this.state;
    data[e.target.name] = e.target.value;
    this.setState({ data });
  };

  handleChangeSelect = (e) => {
    const { data } = this.state;
    data[e.target.name] = e.target.value;
    this.setState({ data });
  };

  handleDeviceChange = (e) => {
    const { data } = this.state;
    data[e.target.name] = e.target.value;
    this.setState({ data });
  };

  requestParams = () => {
    const {
      data: { selected_bots, ...rest },
    } = this.state;
    const { id } = this;

    return {
      vizitins: {
        id,
        ...rest,
        bot_id: selected_bots?.value,
      },
    };
  };

  onSubmit = () => {
    const { id, requestParams, props } = this;
    let requestValue = requestParams();
    const action = id ? updateVizitin : createVizitin;
    action(requestValue, id)
      .then((data) => {
        success(data.message);
        props.handleSubmitSuccess();
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  render() {
    const { device } = this.props;
    const { data } = this.state;

    const {
      id,
      handleChange,
      multiselectChange,
      handleChangeSelect,
      handleDeviceChange,
    } = this;
    const props = {
      id,
      data,
      device,
      handleChangeSelect,
      handleChange,
      multiselectChange,
      handleDeviceChange,
    };
    return (
      <>
        <Form {...props} />
      </>
    );
  }
}
export default DialogForm("Vizitin", 620)(CRUD);
