import React from "react";
import { titleize, formatTime } from "helpers";

const StatusTiles = ({ status }) => {
  const colors = {
    Accepted: "#4baf50",
    Queued: "#f0c806eb",
    Sending: "#3d90ff",
    Sent: "#3b8bf7",
    Failed: "#eb4846",
    Delivered: "#4baf50",
    Received: "#4baf50",
    Unknown: "#7c8107de",
    UnDelivered: "#eb4846",
    Read: "#4baf50",
    Receiving: "#3d90ff",
  };
  const backgroundColor = colors[status] || "#7c8107de";
  return (
    <React.Fragment>
      <span
        style={{
          backgroundColor: backgroundColor,
          color: "#ffff",
          margin: "5px 5px 5px 5px",
          borderRadius: 50,
          padding: "5px 15px 5px",
          whiteSpace: "nowrap",
        }}
      >
        {titleize(status)}
      </span>
    </React.Fragment>
  );
};

export const columns = () => [
  {
    title: "Subject",
    dataIndex: "subject",
    align: "center",
  },
  {
    title: "Recipient",
    dataIndex: "recipient",
    render: (row) => row.phone,
    align: "center",
  },
  {
    title: "From",
    dataIndex: "from",
    align: "center",
  },
  {
    title: "Sent At",
    dataIndex: "created_at",
    render: (created_at) => `${formatTime(created_at)}`,
    align: "center",
  },
  {
    title: "Medium",
    dataIndex: "medium",
    align: "center",
  },
  {
    title: "Status",
    render: (row) => <StatusTiles status={row.status} />,
    align: "center",
  },
];
