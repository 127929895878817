import makeRequest from ".";
import { headers } from "helpers";

function generateParams(params = {}) {
  let kioskTypes = "";
  let status = "";

  if (params.kiosk_type && params.kiosk_type.length >= 1) {
    kioskTypes = params.kiosk_type.map((value) => `&type[]=${value}`).join("");
  }

  if (params?.status) {
    status = params.status.map((value) => `&status[]=${value}`).join("");
  }

  return `${kioskTypes}${status}&page=${params.page ||
    1}&per_page=${params.perPage || 25}`;
}

export function fetchKiosk(requestParams) {
  return makeRequest({
    uri: `/api/v1/kiosks?${generateParams(requestParams)}`,
  });
}

export function createKiosk(requestBody) {
  return makeRequest({
    uri: `/api/v1/kiosks`,
    method: "POST",
    headers: headers(),
    body: JSON.stringify(requestBody),
  });
}

export function updateKiosk(requestBody, id) {
  return makeRequest({
    uri: `/api/v1/kiosks/${id}`,
    method: "PUT",
    headers: headers(),
    body: JSON.stringify(requestBody),
  });
}

export function fetchKioskDetails(mac_adderss) {
  return makeRequest({
    uri: `/api/v1/kiosks/${mac_adderss}/details`,
  });
}

export function fetchEmployeeImage(id, requestParams) {
  return makeRequest({
    uri: `/api/v1/employees/${id}/face_id_images?${generateParams(
      requestParams
    )}`,
  });
}
