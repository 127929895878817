import React, { useEffect, useRef } from "react";
import { Table, Card, Tabs, Button, Tag, Pagination } from "antd";
import { CustomButton } from "components/Button";
import { copy } from "components/notifications/handleResponse";
import { FaRegCopy } from "react-icons/fa";
import { titleize } from "helpers";
import dummyImage from "assets/img/dummy.svg";
import { Empty } from "antd";

const { TabPane } = Tabs;

const copyPath = (path) => {
  let textField = document.createElement("textarea");
  textField.innerText = path;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
  copy("Path Copied");
};

export const TabTable = ({ tabProps }) => {
  const {
    tabs,
    buttons,
    props,
    data,
    column,
    tabOnChange,
    tabName,
    workflow,
    onSelect,
    selected_id,
    total_entries,
    page,
    perPage,
    handlePageChange,
    curr_id,
  } = tabProps;

  const lastProps = useRef({ curr_id });

  useEffect(() => {
    lastProps.current = curr_id;
  }, [curr_id]);

  let ids = curr_id;
  if (selected_id) {
    ids = selected_id === curr_id ? curr_id : selected_id;
  }

  return (
    <div style={{ padding: "0 32px" }}>
      <Card
        style={{
          margin: 0,
          boxShadow:
            "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
        }}
      >
        <Tabs defaultActiveKey={tabName} onChange={tabOnChange}>
          {workflow && (
            <TabPane tab="Workflow" key="work">
              {buttons && (
                <p style={{ margin: "12px 0px" }}>
                  <CustomButton props={props} />
                </p>
              )}
              <Table
                dataSource={data}
                columns={column}
                rowSelection={
                  onSelect && {
                    selectedRowKeys: [ids],
                    type: "radio",
                    onSelect: (row) => onSelect(row.id),
                  }
                }
                rowKey={(row) => row.id}
              />
            </TabPane>
          )}

          {tabs &&
            tabs.map((obj) => {
              return (
                <TabPane tab={obj.tab} key={obj.key}>
                  {obj.table ? (
                    <Table
                      dataSource={obj.data}
                      columns={obj.column}
                      rowSelection={
                        onSelect && {
                          selectedRowKeys: [ids],
                          type: "radio",
                          onSelect: (row) => onSelect(row.id),
                        }
                      }
                    />
                  ) : (
                    <>
                      <p></p>
                      {obj?.radioProps && (
                        <div
                          style={{
                            display: "flex",
                            cursor: "pointer",
                            justifyContent: "flex-end",
                            paddingBottom: "14px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              cursor: "pointer",
                              border: "1px solid transparent",
                              borderRadius: "4px",
                              boxShadow: "0 2px 0 rgb(0 0 0 / 2%)",
                              borderColor: "#d9d9d9",
                            }}
                          >
                            {obj.radioProps.rtbn.map((arr, i) => {
                              return (
                                <div
                                  className={arr.class}
                                  onClick={() => arr.onClick(arr.value)}
                                  key={i}
                                  style={{
                                    padding: "6px 8px",
                                    borderRight: "1px solid #d9d9d9 ",
                                    borderRadius: "2px",
                                  }}
                                  id="rbtn-click"
                                >
                                  {arr.label}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                      {obj.data.length === 0 ? (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      ) : (
                        <div className="card">
                          {obj.data.map((obj, i) => {
                            return (
                              <Card
                                key={i}
                                title={
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <p style={{ margin: 0 }}>
                                      {statusStyle(obj.status)}
                                    </p>
                                    {obj?.image_path && (
                                      <Button
                                        onClick={() => copyPath(obj.image_path)}
                                        value={obj.image_path}
                                        size="large"
                                        style={{
                                          margin: "5px",
                                          fontSize: "23px",
                                          padding: "5px",
                                        }}
                                      >
                                        <FaRegCopy />
                                      </Button>
                                    )}
                                  </div>
                                }
                                style={{ width: 280, marginBottom: "18px" }}
                                id="custom_card"
                              >
                                <div style={{ display: "flex" }}>
                                  <img
                                    src={obj.image_path || dummyImage}
                                    style={{
                                      width: "5em",
                                      borderRadius: "50px",
                                      height: "4.7rem",
                                      fontSize: "15px",
                                    }}
                                  />
                                  <div style={{ padding: "0px 12px" }}>
                                    <p className="word-break">{obj.name}</p>
                                    <p className="word-break">{obj.phone}</p>
                                  </div>
                                </div>
                              </Card>
                            );
                          })}
                        </div>
                      )}

                      {total_entries > 0 && (
                        <Pagination
                          defaultCurrent={1}
                          current={page}
                          defaultPageSize={perPage}
                          onChange={handlePageChange}
                          total={total_entries}
                          showSizeChanger={false}
                          style={{ textAlign: "end" }}
                        />
                      )}
                    </>
                  )}
                </TabPane>
              );
            })}
        </Tabs>
      </Card>
    </div>
  );
};

function statusStyle(state) {
  const style =
    state === "untrained"
      ? {
          color: "#ffa705",
          borderColor: "#ffa500",
          background: "#fff5e9",
        }
      : state === "published"
      ? {
          color: "#5acf13",
          borderColor: "#47b111",
          background: "#f3fff0",
        }
      : state === "deleted"
      ? {
          color: "#ff0000",
          borderColor: "#ff0000",
          background: "#fff5e9",
        }
      : state === "trained"
      ? {
          color: "#cf1322",
          borderColor: "#cf1322",
          background: "#fff1f0",
        }
      : {
          color: "#00B7D1 ",
          borderColor: "#36BCCE ",
          background: "#EEF8FA ",
        };
  return (
    <Tag style={{ ...style }}>{`${titleize(state ? state : "Created")}`}</Tag>
  );
}
