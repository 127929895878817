import makeRequest from ".";

export function fetchTiles(display, trend) {
  display = display.map((val) => `&display[]=${val}`).join("");
  trend = trend.map((val) => `&trend[]=${val}`).join("");

  return makeRequest({
    uri: `/api/v1/employees/tiles?${display}${trend}`,
  });
}

export function EmployeesEntry() {
  return makeRequest({
    uri: `/api/v1/employee_entries/recent`,
  });
}

export function VisitorEntry() {
  return makeRequest({
    uri: `/api/v1/visits/recent`,
  });
}

export function trafficCamera() {
  return makeRequest({
    uri: `/api/v1/cameras?&type=LnprCamera`,
  });
}

export function trafficData(duration) {
  return makeRequest({
    uri: `/api/v1/vehicle_entries/traffic_flow?duration=${duration}`,
  });
}

export function alertsRaised() {
  return makeRequest({
    uri: `/api/v1/alerts?status[]=raised&per_page=5`,
  });
}
