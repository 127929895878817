import React, { Component } from "react";
import { createOutsider, updateOutsider, deleteOutsider } from "api/outsiders";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import DialogForm from "hoc/dialogForm";
import Form from "./Form";

class CRUD extends Component {
  constructor(props) {
    super(props);
    this.id = undefined;
    this.state = {
      open: false,
      name: "",
      phone: "",
      entry_msg: "",
      exit_msg: "",
      vehicles: [],
      country_code: 65,
    };
  }

  handleChange = (event, index, id) => {
    const { country_code } = this.state;
    if (index !== undefined) {
      let vehicles = this.state.vehicles;
      vehicles[index][id] = event.target.value;
      this.setState({ vehicles });
    } else {
      if (event.target.name === "country_code") {
        if (event.target.value === country_code) {
          this.setState({ [event.target.name]: event.target.value });
        } else {
          this.setState({ "phone": "" });
          this.setState({ [event.target.name]: event.target.value });
        }
      } else {
        this.setState({ [event.target.name]: event.target.value });
      }
    }
  };

  requestParams = () => {
    const {
      name,
      phone,
      entry_msg,
      exit_msg,
      vehicles,
      country_code,
    } = this.state;

    let vehicle_ids =
      (vehicles && vehicles.map((vehicle) => vehicle.value)) || [];
    return {
      visitors: {
        name,
        phone,
        entry_msg,
        exit_msg,
        vehicle_ids,
        country_code,
      },
    };
  };

  onSubmit = () => {
    const { id, props } = this;
    props.submitClick();
    const method = !id ? createOutsider : updateOutsider;
    method(this.requestParams(), id)
      .then((data) => {
        success(data.message);
        this.props.handleSubmitSuccess();
        props.submitClick();
      })
      .catch((error) => {
        props.submitClick();
        handleResponse(error);
      });
  };

  componentDidMount = () => {
    if (this.props.data) {
      const {
        id,
        name,
        phone,
        entry_msg,
        exit_msg,
        vehicles,
        country_code,
      } = this.props.data;

      this.id = id;
      this.setState({
        name,
        phone,
        entry_msg,
        exit_msg,
        country_code,
        vehicles:
          vehicles &&
          vehicles.map((vehicle) => {
            return {
              value: vehicle.id,
              label: vehicle.number_plate,
            };
          }),
      });
    }
  };

  onDelete = () => {
    this.setState({ open: true });
  };

  confirmDelete = () => {
    const { id } = this;

    deleteOutsider(id)
      .then(() => {
        this.setState({ open: false });
        success("Deleted Successfully..!!");
        this.props.handleSubmitSuccess();
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  cancelDelete = () => {
    this.setState({ open: false });
  };

  render() {
    const { handleChange } = this;
    const { dateFilter } = this.props;

    const {
      name,
      phone,
      entry_msg,
      exit_msg,
      vehicles,
      country_code,
    } = this.state;
    const OutsiderProps = {
      dateFilter,
      name,
      phone,
      entry_msg,
      exit_msg,
      vehicles,
      handleChange,
      country_code,
    };
    return <Form {...OutsiderProps} />;
  }
}
export default DialogForm("Visitors")(CRUD);
