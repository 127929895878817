import React, { Component } from "react";
import DialogForm from "hoc/dialogForm";
import FormHandle from "../form";
import { Row } from "antd";
import { createEmployeesData, updateEmployeesData } from "api/employee";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";

class CRUDEmployee extends Component {
  constructor(props) {
    super(props);
    this.handleChild = React.createRef();
    this.state = { data: { status: "active", country_code: 65 } };
  }

  componentDidMount() {
    const { data } = this.props;
    if (data?.id) {
      this.setState({ data: data });
    }
  }

  handleChange = (e) => {
    const { data } = this.state;
    const { name, value } = e.target;
    if (name === "country_code" && value !== data.country_code) {
      this.setState({ data: { ...data, phone: "", [name]: value } });
    } else {
      this.setState({ data: { ...data, [name]: value } });
    }
  };

  onSubmit = () => {
    const {
      state: { data },
      props: { handleSubmitSuccess },
    } = this;
    const fileData = this?.handleChild?.current?.requestParams();

    const formData = new FormData();
    if (fileData) {
      formData.append("employees[image]", fileData);
    }

    Object.keys(data).filter((arr) => {
      if (arr === "id" || arr === "employee_image") {
        return;
      } else {
        return formData.append(`employees[${arr}]`, data[arr]);
      }
    });
    const method = data.id ? updateEmployeesData : createEmployeesData;

    method(formData, data.id)
      .then(({ message }) => {
        handleSubmitSuccess();
        success(message);
      })
      .catch((err) => {
        handleResponse(err);
      });
  };

  render() {
    const { handleChange } = this;
    const { name, status, phone, country_code, email } = this.state.data;
    const handleFields = [
      {
        tab: "Details",
        key: "details",
        value: [
          {
            label: "Name",
            name: "name",
            onChange: handleChange,
            validation: true,
            type: "text",
            value: name,
          },
          {
            label: "Status",
            name: "status",
            onChange: handleChange,
            type: "radioBtn",
            options: { Active: "active", Inactive: "inactive" },
            value: status,
          },
          {
            label: "Phone",
            name: "phone",
            onChange: handleChange,
            validation: true,
            type: "phone",
            value: phone,
            country_code,
          },
          {
            label: "Email",
            name: "email",
            onChange: handleChange,
            validation: true,
            type: "text",
            value: email,
          },
        ],
      },
      {
        tab: "Image",
        key: "image",
        value: [
          {
            // label: "Name",
            name: "upload",
            // onChange: handleChange,
            // validation: true,
            type: "upload",
            // value: name,
            handleChild: this.handleChild,
          },
        ],
      },
    ];
    return (
      <Row>
        <FormHandle value={handleFields} />
      </Row>
    );
  }
}

export default DialogForm()(CRUDEmployee);
