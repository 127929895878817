import React from "react";
import { FaceRegistered } from "assets/icon";
import { Button } from "antd";
import { presence } from "helpers";

export const BotIcon = (bot) => {
  if (bot) {
    return <FaceRegistered style={{ width: "auto", height: "auto" }} />;
  }
};

export const Status = ({ values }) => {
  const colors = {
    active: "#4caf50",
    inactive: "#ff5252",
  };

  return (
    <span
      style={{
        color: "#fff",
        backgroundColor: colors[values] || "#9c27b0",
        width: 12,
        height: 12,
        display: "inline-block",
        borderRadius: 50,
      }}
    />
  );
};

export const columns = (editAction, aboutAction) => [
  {
    title: "",
    key: "name",
    dataIndex: "employee_image",
    align: "center",
    render: (row) => BotIcon(row?.image_path),
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    align: "center",
  },
  {
    title: "Phone",
    render: (row) =>
      `${row.country_code ? "+" + row?.country_code : ""} ${presence(
        row.phone
      )}`,
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    align: "center",
    render: (row) => <Status values={row} />,
  },
  {
    title: "Manage",
    align: "center",
    width: "20%",
    render: (row) => {
      return (
        <div>
          <Button
            onClick={() => editAction(row)}
            style={{
              margin: "0px 10px 5px 10px",
            }}
          >
            Edit
          </Button>
          <Button
            onClick={() => aboutAction(row)}
            style={{
              margin: "0px 10px 5px 10px",
            }}
          >
            Details
          </Button>
        </div>
      );
    },
  },
];
