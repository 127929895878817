import React from "react";
import SimpleTextInput from "components/inputs/simpleTextInput";
import SimplePhoneInput from "components/inputs/simplePhoneInput";
import { Col, Tabs } from "antd";
import Select from "components/inputs/Multiselect";
import RadioButton from "components/inputs/radioButton";
import UploadFile from "views/UploadFile";
import {
  DateAndTimeInput,
  DateInput,
  TimeInput,
} from "components/inputs/DateTimeInput";

const { TabPane } = Tabs;
function FormHandle({ value }) {
  let Pop = value[0]?.tab ? Tabs : "div";
  let Rop = value[0]?.tab ? TabPane : "div";
  return (
    <>
      <Pop type="card">
        {value.map((arr) => {
          return (
            <Rop tab={arr?.tab} defaultActiveKey="details" key={arr?.key}>
              {arr.value.map((obj, i) => (
                <InputsHandle key={i} data={obj} />
              ))}
            </Rop>
          );
        })}
      </Pop>
    </>
  );
}

export default FormHandle;

export function InputsHandle({ data }) {
  const handle = (data) => {
    const {
      label,
      value,
      name,
      onChange,
      options,
      defaultValue,
      country_code,
      required,
      handleChild,
      size,
      format,
    } = data;
    let short = size?.short || 24;
    let medium = size?.medium || 12;
    // let large = size?.large || 12;

    switch (data.type) {
      case "text":
        return (
          <Col xs={short} sm={medium} style={{ padding: "0px 10px" }}>
            <SimpleTextInput
              labelText={label}
              name={name}
              value={value}
              onChange={onChange}
              required={required}
            />
          </Col>
        );
      case "phone":
        return (
          <Col xs={short} style={{ padding: "0px 10px" }}>
            <SimplePhoneInput
              countryValues={country_code}
              onChange={(e) => onChange(e)}
              labelText={label}
              name={name}
              value={value}
              length={country_code}
              required={required}
            />
          </Col>
        );
      case "email":
        return (
          <Col xs={short} sm={medium} style={{ padding: "0px 10px" }}>
            <SimpleTextInput
              labelText={label}
              name={name}
              value={value}
              onChange={onChange}
              required={required}
            />
          </Col>
        );
      case "datePicker":
        return (
          <Col xs={short} sm={medium} style={{ padding: "0px 10px" }}>
            <DateInput
              labelText={label}
              onChange={onChange}
              value={value}
              format={format}
              required={required}
            />
          </Col>
        );
      case "timePicker":
        return (
          <Col xs={short} sm={medium} style={{ padding: "0px 10px" }}>
            <TimeInput
              labelText={label}
              onChange={onChange}
              value={value}
              format={format}
              required={required}
            />
          </Col>
        );
      case "dateAndTimePicker":
        return (
          <Col xs={short} sm={medium} style={{ padding: "0px 10px" }}>
            <DateAndTimeInput
              labelText={label}
              onChange={onChange}
              value={value}
              format={format}
              required={required}
            />
          </Col>
        );
      case "radioBtn":
        return (
          <Col xs={short} sm={medium} style={{ padding: "0px 10px" }}>
            <RadioButton
              labelText={label}
              value={value}
              data={options}
              onChange={onChange}
              name="status"
              color={true}
            />
          </Col>
        );
      case "singleSelect":
        return (
          <Col xs={short} sm={medium} style={{ padding: "0px 10px" }}>
            <Select
              {...data}
              heading={label}
              selected={defaultValue}
              options={options}
              handleChange={onChange}
              name={name}
            />
          </Col>
        );
      case "mulitSelect":
        return (
          <Col xs={short} sm={medium} style={{ padding: "0px 10px" }}>
            <Select
              {...data}
              heading={label}
              selected={defaultValue}
              options={options}
              handleChange={onChange}
              name={name}
            />
          </Col>
        );
      case "fin":
        return (
          <Col xs={short} sm={medium} style={{ padding: "0px 10px" }}>
            <SimpleTextInput
              labelText={label}
              name={name}
              value={value}
              onChange={onChange}
              required={required}
            />
          </Col>
        );
      case "upload":
        return (
          <Col xs={short} style={{ padding: "0px 10px" }}>
            <UploadFile
              ref={handleChild}
              accept={".png" || ".svg" || "jpg" || "jpeg"}
              {...{
                title: "",
                uploadButton: false,
              }}
            />
          </Col>
        );
      default:
        return;
    }
  };
  return <p>{handle(data)}</p>;
}
