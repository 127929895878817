import React from "react";
import { Row, Col } from "antd";
import SimpleTextInput from "components/inputs/simpleTextInput";
import SimplePhoneInput from "components/inputs/simplePhoneInput";

function Form({
  name,
  phone,
  handleChange,
  country_code,
}) {
  return (
    <React.Fragment>
      <Row style={{ padding: "10px" }}>
        <Row>
          <Col xs={24} sm={12} md={12} style={{ padding: "0px 10px" }}>
            <SimpleTextInput
              labelText="Name"
              name="name"
              value={name}
              type="text"
              onChange={handleChange}
              validation={true}
            />
          </Col>
        </Row>
        <Row>
          <SimplePhoneInput
            countryValues={country_code}
            onChange={(e) => handleChange(e)}
            labelText="Phone"
            name="phone"
            value={phone}
            length={country_code}
          />
        </Row>
      </Row>
    </React.Fragment>
  );
}
export default Form;
