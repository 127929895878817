import React from "react";
import { Button } from "antd";
import { presence } from "helpers";
import moment from "moment";

export const columns = (editOutsider, outsiderHistory, updateAction) => [
  {
    title: "Name",
    dataIndex: "name",
    render: (name) => `${presence(name)}`,
    align: "center",
  },
  {
    title: "Phone",
    render: (row) =>
      `${row.country_code ? "+" + row?.country_code : ""} ${presence(
        row.phone
      )}`,
    align: "center",
  },
  {
    title: "Last Active",
    render: (row) =>
      row.latest_entry_at
        ? moment(row.latest_entry_at).format("DD-MM-yyyy HH:mm:ss")
        : "-",
    align: "center",
  },
  {
    title: "No of entry",
    dataIndex: "no_of_visitor_entry",
    align: "center",
  },
  {
    title: "Manage",
    render: (row) => {
      return (
        <React.Fragment>
          {updateAction() && (
            <Button
              onClick={() => editOutsider(row)}
              style={{
                margin: "0px 10px 5px 10px",
              }}
            >
              Edit
            </Button>
          )}
          <Button
            onClick={() => outsiderHistory(row)}
            style={{
              margin: "5px 10px 0px 10px",
            }}
          >
            History
          </Button>
        </React.Fragment>
      );
    },
    align: "center",
  },
];
