import React, { Component } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { MdLocationCity, MdPerson } from "react-icons/md";
import { FaTabletAlt } from "react-icons/fa";
import { VehicleEntriesContext } from "context/ContextProvider";
import { presence, formatTime } from "helpers";
import { Col } from "antd";

const styles = {
  topLabel: {
    fontSize: 16,
    position: "absolute",
    top: -28,
    left: 0,
    width: "100%",
    padding: "10px 25px",
    backgroundColor: "#1dbace",
  },
  labelTitle: {
    margin: 0,
    fontWeight: "bold",
    textAlign: "center",
    color: "white",
  },
};

const Timeline = ({ color, icon, body }) => {
  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      iconStyle={{
        background: color,
        color: "#fff",
      }}
      icon={icon}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        {body}
      </div>
    </VerticalTimelineElement>
  );
};

const renderTR = (name, value) => {
  let unit_num = value?.split(",");
  return (
    <tr>
      <td style={{ textAlign: "right" }}>
        <h3>{name}</h3>
      </td>
      <td style={{ minWidth: 40, textAlign: "center", paddingBottom: 10 }}>
        -
      </td>
      <td>
        {unit_num?.map((number) => (
          <h2 style={{ fontSize: "1.1rem" }}>{number}</h2>
        ))}
      </td>
    </tr>
  );
};

const GroupContentCard = ({ title, data }) => {
  return (
    <React.Fragment>
      <div style={styles.topLabel}>
        <h4 style={styles.labelTitle}>{title}</h4>
      </div>
      <Col style={{ textAlign: "center" }}>
        <span style={{ display: "table" }}>
          <table>
            <tbody style={{ textAlign: "left" }}>
              {Object.keys(data).map(
                (key) => data[key] && renderTR(key, data[key])
              )}
            </tbody>
          </table>
        </span>
      </Col>
    </React.Fragment>
  );
};

const GroupCard = ({ title, data }) => {
  return (
    <React.Fragment>
      <div style={styles.topLabel}>
        <h4 style={styles.labelTitle}>{title}</h4>
      </div>
      <Col>
        <span
          style={{
            margin: "5px 0px",
            textAlign: "center",
          }}
        >
          <span>
            <h3 style={{ margin: 0 }}>{presence(data)}</h3>
          </span>
        </span>
      </Col>
    </React.Fragment>
  );
};

class Main extends Component {
  render() {
    return (
      <VerticalTimeline>
        <VehicleEntriesContext.Consumer>
          {({ data: { device, visitor_details, visitor_entries } }) => {
            const {
              company_name,
              email,
              country_code,
              name,
              phone,
            } = visitor_details[0];

            return (
              <React.Fragment>
                {device?.name && (
                  <Timeline
                    color="rgb(188, 98, 89)"
                    icon={<FaTabletAlt style={{ fontSize: 14 }} />}
                    body={<GroupCard title={"Device "} data={device.name} />}
                  />
                )}

                {visitor_details[0] && (
                  <Timeline
                    color="#ff407e"
                    icon={<MdPerson />}
                    body={
                      <GroupContentCard
                        title={"Visitor Details"}
                        data={{
                          Name: name,
                          "Company Name": presence(company_name),
                          Phone: `+${country_code} ${phone}`,
                          Email: email,
                        }}
                      />
                    }
                  />
                )}
                {visitor_entries &&
                  visitor_entries.map((obj) => {
                    return (
                      <Timeline
                        color="#2e26a7"
                        icon={<MdLocationCity />}
                        body={
                          <GroupContentCard
                            title={"Entry Details"}
                            data={{
                              "Entry at": formatTime(
                                obj.in_time,
                                "MMMM Do YYYY, hh:mm:ss a"
                              ),
                            }}
                          />
                        }
                      />
                    );
                  })}
              </React.Fragment>
            );
          }}
        </VehicleEntriesContext.Consumer>
      </VerticalTimeline>
    );
  }
}

export default Main;
