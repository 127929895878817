import { siteName } from "config";
import moment from "moment";

export function getCookie(name) {
  let cookie = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return cookie && cookie[2];
}

export function setCookie(name, value, days = 0, hours = 0) {
  let date = new Date();
  if (days || hours) {
    date.setTime(
      date.getTime() + hours * 60 * 60 * 1000 + 24 * 60 * 60 * 1000 * days
    );
    document.cookie =
      name + "=" + value + ";path=/;expires=" + date.toGMTString();
  } else {
    document.cookie = name + "=" + value + ";path=/";
  }
}

export function popCookie(name) {
  let value = getCookie(name);
  deleteCookie(name);
  return value;
}

export function deleteCookie(name) {
  setCookie(name, "", -1);
}

export function deleteAllCookie() {
  document.cookie.split(";").forEach(function(c) {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
}

export async function kickUser() {
  await deleteAllCookie();
  await indexedDB.deleteDatabase(siteName);
  await window.localStorage.clear();
  await window.location.reload();
}

export const jsonHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export function shuffleArray(array) {
  let i = array.length - 1;
  for (; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

export function isArray(a) {
  return !!a && a.constructor === Array;
}

export function isObject(a) {
  return !!a && a.constructor === Object;
}

export function titleize(str) {
  if (!str) {
    return str;
  }

  return str
    .split(" ")
    .map(function(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    })
    .join(" ")
    .split("_")
    .map(function(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    })
    .join(" ");
}

export function isAuthenticated() {
  return !!getCookie("token");
}

export const headers = (formData) => {
  // if (!getCookie("active")) {
  //   lastLogin();
  // }

  return formData === true
    ? {
        Authorization: getCookie("token"),
      }
    : {
        ...jsonHeaders,
        Authorization: getCookie("token"),
      };
};

export function timeDiff(startTime, endTime) {
  if (!startTime || !endTime) return "-";

  if (startTime < endTime)
    return moment.duration(moment(endTime).diff(moment(startTime))).humanize();
  else
    return moment.duration(moment(endTime).diff(moment(startTime))).humanize(1);
}

export function formatTime(time, format) {
  if (!time) return "-";

  format = format || "DD-MM-YYYY HH:mm:ss";
  return moment(time).format(format);
}

export function presence(string) {
  if (string === "undefined" || string === null) {
    return "-";
  }
  return string ? string : "-";
}

export function noValue(number) {
  return number ? number : 0;
}

export function keyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export const truncate = (input, limit) => {
  if (!input) return;
  return input.length > limit ? `${input.substring(0, limit - 5)}...` : input;
};

export function formatDate(date) {
  return (
    moment(date)
      .utc()
      .format("YYYY-MM-DD H:mm:ss") + "+0000"
  );
}

export function readParseLS(key) {
  try {
    const data = JSON.parse(window.localStorage.getItem(key));
    if (!data || "object" !== typeof data) {
      return;
    }
    return data;
  } catch (err) {
    return;
  }
}

export function presentationAccess() {
  let user_roles = window.localStorage.getItem("user_roles");
  user_roles = user_roles && JSON.parse(user_roles);

  let sub_permissions = user_roles && user_roles["api/v1/alerts"];
  return (
    sub_permissions?.includes("aggs") && sub_permissions?.includes("index")
  );
}

export function createAccess(action) {
  let user_roles = window.localStorage.getItem("user_roles");
  user_roles = user_roles && JSON.parse(user_roles);

  let model = user_roles && user_roles[`api/v1/${action}`];
  return model?.includes("create");
}

export function removeAccess(action) {
  let user_roles = window.localStorage.getItem("user_roles");
  user_roles = user_roles && JSON.parse(user_roles);

  let model = user_roles && user_roles[`api/v1/${action}`];
  return model?.includes("remove_face");
}

export function updateAccess(action) {
  let user_roles = window.localStorage.getItem("user_roles");
  user_roles = user_roles && JSON.parse(user_roles);

  let model = user_roles && user_roles[`api/v1/${action}`];
  return model?.includes("update");
}

export function checkAccess(action) {
  let user_roles = window.localStorage.getItem("user_roles");
  user_roles = user_roles && JSON.parse(user_roles);

  let model = user_roles && user_roles[`api/v1/${action}`];
  return model?.includes("workflows");
}

export function showAccess(action) {
  let user_roles = window.localStorage.getItem("user_roles");
  user_roles = user_roles && JSON.parse(user_roles);

  let model = user_roles && user_roles[`api/v1/${action}`];
  return model?.includes("show");
}

export function uploadAccess(action) {
  let user_roles = window.localStorage.getItem("user_roles");
  user_roles = user_roles && JSON.parse(user_roles);

  let model = user_roles && user_roles[`api/v1/${action}`];
  return model?.includes("import");
}

export function setHours(datetime, type) {
  datetime = datetime ? new Date(datetime) : new Date();
  let response = undefined;
  switch (type) {
    case "START":
      response = new Date(datetime).setHours(0, 0, 0, 0);
      break;
    case "END":
      response = new Date(datetime).setHours(23, 59, 59, 999);
      break;
    default:
      response = new Date(datetime);
      break;
  }
  return response;
}

export function generateDateTime(datetime) {
  datetime = datetime ? new Date(datetime) : new Date();
  let arr = [];
  let start_value = setHours(datetime, "START");
  let end_value = setHours(datetime, "END");
  arr.push(formatDate(start_value));
  for (let i = 1; i <= 5; i++) {
    arr.push(formatDate(datetime.setHours(i * 4, 0, 0, 0)));
  }
  arr.push(formatDate(end_value));
  return arr;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function removeSpecialChar(str, arg) {
  return str.replace(/[^a-zA-Z 0-9]+/g, arg);
}

export function splitWords(str, arg) {
  return str.split(arg);
}

export function varHumanize(str) {
  var a = removeSpecialChar(str, " ");
  var words = splitWords(a, " ");
  var humanize = "";
  words.forEach((word) => {
    humanize += capitalizeFirstLetter(word) + " ";
  });
  return humanize;
}

export function compareTwoArray(array1, array2) {
  if (!array1 || !array2) return;

  return (
    array1.length === array2.length &&
    array1.every((value, index) => value === array2[index])
  );
}

export function exportToJson(data, filename = "download") {
  let contentType = "application/json;charset=utf-8;";
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    let blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(data)))], {
      type: contentType,
    });
    navigator.msSaveOrOpenBlob(blob, filename + ".json");
  } else {
    let a = document.createElement("a");
    a.download = filename + ".json";
    a.href =
      "data:" + contentType + "," + encodeURIComponent(JSON.stringify(data));
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}

export function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function refreshAPI(
  callFunc,
  refreshTiming,
  key,
  inactiveRefreshTiming
) {
  // this.timer = setInterval(callFunc, refreshTiming);
  if (window.localStorage.tokenSent === "1") {
    document.addEventListener("visibilitychange", () => {
      console.log(document.visibilityState);
      if (document.visibilityState === "hidden") {
        this.timer = setInterval(callFunc, inactiveRefreshTiming);
      } else if (document.visibilityState === "visible") {
        if (this.timer) {
          clearInterval(this.timer);
          this.timer = null;
        }
      }
    });

    // messaging.onMessage(payload => {
    //   if (payload?.data?.[key] === "true") {
    //     callFunc();
    //   }
    //   if (this.debouncer) {
    //     clearTimeout(this.debouncer);
    //     this.debouncer = null;
    //   }
    //   this.debouncer = setTimeout(callFunc, 6000);
    // });
  } else {
    this.timer = setInterval(callFunc, refreshTiming);
  }
}

export let groupBy = (objects, key) => {
  let result = objects.reduce(function(r, a) {
    r[a[key]] = r[a[key]] || [];
    r[a[key]].push(a);
    return r;
  }, Object.create(null));
  return result;
};

export function phoneLimit(value) {
  switch (value) {
    case 65:
      return 8;
    case 60:
      return 8;
    case 62:
      return 10;
    case 91:
      return 10;
    case 44:
      return 10;
  }
}
