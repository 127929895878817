import React, { Component } from "react";
import { Row, Col } from "antd";
import moment from "moment";
import siteLogo from "assets/img/logo.png";
class Header extends Component {
  state = {
    date: moment().format("D MMM"),
    time: moment().format("HH:mm:ss"),
  };
  componentDidMount() {
    this.timer = setInterval(
      () =>
        this.setState({
          date: moment().format("D MMM"),
          time: moment().format("HH:mm:ss"),
        }),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }
  render() {
    const { goBack } = this.props;
    return (
      <Row
        style={{
          background: "#fff",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: "0px 0px 35px 35px",
          height: 92,
        }}
      >
        <Col
          sm={4}
          style={{
            display: "flex",
            alignItems: "center",
            height: "92px",
          }}
        >
          <img
            src={siteLogo}
            style={{
              height: "100%",
              marginLeft: 35,
              padding: "22px 35px",
              cursor: "pointer",
              width: "100%",
            }}
            alt="condo"
            onClick={goBack}
          />
        </Col>
        <Col
          sm={16}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <h1
            style={{
              margin: 0,
              fontSize: "3em",
              color: "#000",
              fontStyle: "normal",
              fontFamily: "auto",
            }}
          >
            Intelligent Alert System
          </h1>
        </Col>
        <Col
          sm={4}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-end",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              paddingRight: 35,
            }}
          >
            <h2
              style={{
                fontFamily: "Roboto Slab",
                fontWeight: "normal",
                margin: 0,
              }}
            >
              {this.state.date}
            </h2>
            <h5
              style={{
                fontSize: "1.7em",
                fontFamily: "Roboto Slab",
                fontWeight: "normal",
                margin: 0,
              }}
            >
              {this.state.time}
            </h5>
          </div>
        </Col>
      </Row>
    );
  }
}

export default Header;
