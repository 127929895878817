import makeRequest from ".";
import { headers } from "helpers";

function generateParams(params = {}) {
  let cameraTypes = "";
  if (params.type && params.type.length <= 1) {
    cameraTypes = params.type.map((value) => `&type=${value}`).join("");
  }
  return `${cameraTypes}&page=${params.page || 1}&per_page=${params.perPage ||
    25}`;
}

export function fetchCamera(requestParams) {
  return makeRequest({
    uri: `/api/v1/cameras?${generateParams(requestParams)}`,
  });
}

export function createCamera(requestBody) {
  return makeRequest({
    uri: `/api/v1/cameras`,
    method: "POST",
    headers: headers(),
    body: JSON.stringify(requestBody),
  });
}

export function updateCamera(requestBody, id) {
  return makeRequest({
    uri: `/api/v1/cameras/${id}`,
    method: "PUT",
    headers: headers(),
    body: JSON.stringify(requestBody),
  });
}

export function cameraDetails(mac_address) {
  return makeRequest({
    uri: `/api/v1/cameras/${mac_address}/details`,
  });
}

