import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import {
  RaisedAlerts,
  JustEnteredEmployee,
  JustEnteredVisitor,
} from "./dashboardCharts";
import { dashboardGraph } from "config";

/** Dashboard Chart items */
export const graphs = {
  raised_alerts: {
    title: "raised_alerts",
    child: <RaisedAlerts />,
  },
  just_employee: {
    title: "just_employee",
    child: <JustEnteredEmployee />,
  },
  just_visitor: {
    title: "just_visitor",
    child: <JustEnteredVisitor />,
  },
};

const render_card = ({ title, child }) => {
  return (
    <Col
      xs={24}
      md={12}
      lg={8}
      style={{ paddingRight: "15px" }}
      data-json={title}
    >
      {child}
    </Col>
  );
};

/** Render Graph */
const filterGraph = (graph) => {
  let item = graphs[graph];
  return render_card(item);
};

export const GraphDashboard = () => {
  const [cards, setCards] = useState(dashboardGraph);
  useEffect(() => {
    let graph_tiles = localStorage.getItem("graph_tiles");
    if (graph_tiles) {
      setCards(JSON.parse(graph_tiles));
    }
  }, []);
  return (
    <Row>
      {Array.isArray(cards) && cards.map((graph) => filterGraph(graph))}
    </Row>
  );
};
