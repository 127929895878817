import makeRequest from ".";

function generateParams(params = {}) {
  let statuses = "";
  let deviceName = "";
  let entryMode = "";
  let phone = "";
  let empName = "";
  let name = "";
  let companyName = "";
  if (params.status) {
    statuses = params.status.map((value) => `&status[]=${value.value}`).join("");
  }

  if (params.phone) {
    phone = params.phone.map((value) => `&phone[]=${value.value}`).join("");
  }

  if (params.empName) {
    empName = params.empName.map((value) => `&employee_name[]=${value.value}`).join("");
  }

  if (params.companyName) {
    companyName = params.companyName.map((value) => `&company_name[]=${value.value}`).join("");
  }

  if (params.name) {
    name = params.name.map((value) => `&name[]=${value.value}`).join("");
  }

  if (params.deviceName) {
    deviceName = params.deviceName.map((value) => `&device_name[]=${value.value}`).join("");
  }

  if (params.entryMode) {
    entryMode = params.entryMode.map((value) => `&entry_mode[]=${value.value}`).join("");
  }

  return `from_time=${params.fromTime}&to_time=${
    params.toTime
  }${statuses}${entryMode}${deviceName}${companyName}${empName}${name}${phone}&page=${params.page ||
    1}&per_page=${params.perPage || 25}&order=created_at desc`;
}

export function fetchEntryData(requestParams) {
  return makeRequest({
    uri: `/api/v1/invites?${generateParams(requestParams)}`,
  });
}


export function fetchVisitsData(requestParams) {
  return makeRequest({
    uri: `/api/v1/visits?${generateParams(requestParams)}`,
  });
}

export function aggregationVisits(requestParams) {
  return makeRequest({
    uri: `/api/v1/visits/aggs?${generateParams(requestParams)}`,
  });
}


export function aggregation(requestParams) {
  return makeRequest({
    uri: `/api/v1/invites/aggs?${generateParams(requestParams)}`,
  });
}

export function createInvites(requestBody) {
  return makeRequest({
    uri: "/api/v1/invites",
    method: "POST",
    body: requestBody,
  });
}

export function updateInvites(requestBody, id) {
  return makeRequest({
    uri: `/api/v1/invites/${id}`,
    method: "PUT",
    body: requestBody,
  });
}