import React from "react";
import SimpleTextInput from "components/inputs/simpleTextInput";
import { Row, Col, Tabs } from "antd";
import RadioButton from "components/inputs/radioButton";
import Singleselect from "components/inputs/Singleselect";
import { fetchPeopleData } from "api/peoples";
import PropTypes from "prop-types";

const { TabPane } = Tabs;

const Form = ({
  entity_type,
  status,
  handleChange,
  remarks,
  visitors,
  handleCreateVisitors,
}) => {

  const visitorData = async (search) =>
    await fetchPeopleData({ perPage: 5, search }, "visitors").then(({ data }) =>
      data.map((value) => ({ label: value.phone, value: value.id }))
    );

  return (
    <Row style={{ minWidth: 500 }}>
      <Tabs
        type="card"
        activeKey={entity_type}
        onChange={(value) =>
          handleChange({ target: { value, name: "entity_type" } })
        }
        style={{ overflow: "visible" }}
      >
        <TabPane tab="Vehicle" key="Vehicle">
          <Row spacing={3} style={{ padding: 10 }}>
            <Col xs={24} sm={12} style={{ padding: 10 }}>
              <RadioButton
                labelText="Status"
                value={status}
                data={{ Active: "active", Inactive: "inactive" }}
                onChange={handleChange}
                name="status"
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Visitor" key="Visitor">
          <Row spacing={3} style={{ padding: 10 }}>
            <Col xs={24} sm={12} md={12} style={{ padding: 10 }}>
              <Singleselect
                async
                allowCreate
                name="visitors"
                selected={visitors}
                heading={"Phone"}
                dataPromise={visitorData}
                handleChange={handleChange}
                handleCreate={handleCreateVisitors}
              />
            </Col>
            <Col xs={24} sm={12} style={{ padding: 10 }}>
              <RadioButton
                labelText="Status"
                value={status}
                data={{ Active: "active", Inactive: "inactive" }}
                onChange={handleChange}
                name="status"
              />
            </Col>
          </Row>
        </TabPane>
      </Tabs>
      <Col xs={24} sm={24} md={24} style={{ padding: "0px 20px" }}>
        <SimpleTextInput
          labelText="Remarks"
          name="remarks"
          value={remarks}
          onChange={handleChange}
        />
      </Col>
    </Row>
  );
};

Form.defaultProps = {
  status: "active",
};

Form.propTypes = {
  entity_type: PropTypes.string,
  status: PropTypes.oneOf(["active", "inactive"]),
  remarks: PropTypes.string,
  vehicle: PropTypes.object,
  visitor: PropTypes.object,
  handleChange: PropTypes.func,
  handleCreateVisitors: PropTypes.func,
};

export default Form;
