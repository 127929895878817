import React from "react";
import { presence } from "helpers";
import { DurationComp } from "data/index";
import moment from "moment";
import { StatusTile } from "./statusTile";

const columns = () => ({
  visitor_name: {
    title: "Name",
    align: "center",
    dataIndex: "visitor_details",
    render: (row) => `${presence(row[0] && row[0].name)}`,
  },
  email: {
    title: "Email",
    align: "center",
    dataIndex: "visitor_details",
    render: (row) => `${presence(row[0] && row[0].email)}`,
  },
  visitor_phone: {
    title: "Phone",
    align: "center",
    dataIndex: "visitor_details",
    render: (row) => {
      if (row[0]) {
        return `${row[0].country_code ? "+" + row[0].country_code : ""} ${presence(
          row[0].phone
        )}`;
      } else {
        return "-"
      }
    },
  },
  company_name: {
    title: "Comapny",
    align: "center",
    dataIndex: "visitor_details",
    render: (row) => `${presence(row[0] && row[0].company_name)}`,
  },
  duration: {
    title: "Duration",
    render: (row) => <DurationComp row={row} />,
    align: "center",
  },
  in_time: {
    title: "Entry Time",
    render: (row) => {
      return (
        <>
          {row.visitor_entries?.[0]?.in_time ? (
            <p>
              {moment(row.visitor_entries?.[0]?.in_time).format(
                "DD MM YYYY hh:mm:ss"
              )}
            </p>
          ) : (
            "-"
          )}
        </>
      );
    },

    align: "center",
  },
  status: {
    title: "Visited status",
    render: (row) => (
      <StatusTile role_group={row.status && presence(row.status)} />
    ),
    align: "center",
  },
});

function filteredHead({ fields, ...kwargs }) {
  let result = columns({ ...kwargs });
  return fields.map((field) => result[field]);
}

export const VisitorEntries = {
  tabColor: "primary",
  title: "Visitor Entries",
  subTitle: "Entry of people can be monitored here",
  enumerable: ["Guest"],
  columns: filteredHead({
    fields: [
      "visitor_name",
      "visitor_phone",
      "email",
      "company_name",
      "in_time",
      "status",
    ],
  }),
};
