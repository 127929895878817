import React from "react";
import { FaceRegistered } from "assets/icon";
import moment from "moment";
import { presence } from "helpers";

export const BotIcon = (bot) => {
  if (bot) {
    return <FaceRegistered style={{ width: "auto", height: "auto" }} />;
  }
};

export const columns = () => [
  {
    title: "",
    key: "name",
    render: (row) => BotIcon(row.face_icon),
    align: "center",
  },
  {
    title: "Name",
    dataIndex: "employee",
    key: "name",
    align: "center",
    render: (row) => presence(row?.name),
  },
  {
    title: "Phone",
    dataIndex: "employee",
    key: "phone",
    align: "center",
    render: (row) =>
      `${row.country_code ? "+" + row?.country_code : ""} ${presence(
        row.phone
      )}`,
  },
  {
    title: "Entry Mode",
    dataIndex: "entry_mode",
    key: "phone",
    align: "center",
  },
  {
    title: "Entry Time",
    dataIndex: "in_time",
    key: "phone",
    align: "center",
    render: (row) => moment(row).format("DD MMM YYYY HH:mm:ss"),
  },
];
