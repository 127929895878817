import makeRequest from ".";

export function createVisitors(requestBody) {
  return makeRequest({
    uri: "/api/v1/visitors",
    method: "POST",
    body: requestBody,
  });
}

export function updateVisitorsData(requestBody, id) {
  return makeRequest({
    uri: `/api/v1/visitors/${id}`,
    method: "PUT",
    body: requestBody,
  });
}


export function VisitorAnalytics(phone) {
  return makeRequest({
    uri: `/api/v1/visitors/${phone}/analytics`,
  });
}
