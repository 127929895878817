import React, { Component } from "react";
import DialogForm from "hoc/dialogForm";
import FormHandle from "../form";
import { Row } from "antd";
import { createInvites, updateInvites } from "api/inviteVisitor";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";

class CRUDVisitor extends Component {
  constructor(props) {
    super(props);
    this.state = { data: { country_code: 65 } };
  }

  componentDidMount() {
    const { data } = this.props;
    if (data?.id) {
      this.setState({ data: data });
    }
  }

  handleChange = (e) => {
    const { data } = this.state;
    const { name, value } = e.target;
    if (name === "country_code" && value !== data.country_code) {
      this.setState({ data: { ...data, "phone": "", [name]: value } });
    } else {
      this.setState({ data: { ...data, [name]: value } });
    }
  };

  onSubmit = () => {
    const {
      state: { data },
      props: { handleSubmitSuccess },
    } = this;

    const formData = new FormData();
    Object.keys(data).filter((arr) =>
      formData.append(`invitors[${arr}]`, data[arr])
    );

    const method = data.id ? updateInvites : createInvites;
    method(formData, data.id)
      .then(({ message }) => {
        handleSubmitSuccess();
        success(message);
      })
      .catch((err) => {
        handleResponse(err);
      });
  };

  render() {
    const { handleChange } = this;
    const {
      name,
      email,
      phone,
      country_code,
      company_name,
      from_time,
    } = this.state.data;

    const handleFields = [
      {
        value: [
          {
            label: "Name",
            name: "name",
            onChange: handleChange,
            validation: true,
            type: "text",
            value: name,
            required: true,
          },
          {
            label: "Email",
            name: "email",
            onChange: handleChange,
            validation: true,
            type: "text",
            value: email,
            required: true,
          },
          {
            label: "Phone",
            name: "phone",
            onChange: handleChange,
            validation: true,
            type: "phone",
            value: phone,
            country_code,
            required: true,
          },
          {
            label: "Company",
            name: "company_name",
            onChange: handleChange,
            validation: true,
            type: "text",
            value: company_name,
            required: true,
          },
          {
            label: "Select Date",
            onChange: (e) =>
              handleChange({ target: { name: "from_time", value: e } }),
            validation: true,
            type: "datePicker",
            value: from_time,
            required: true,
            format: "dd/MM/yyyy",
          },
        ],
      },
    ];
    return (
      <Row>
        <FormHandle value={handleFields} />
      </Row>
    );
  }
}

export default DialogForm()(CRUDVisitor);
