import React, { Component } from "react";
import AlertUpdate from "./multiResolve_form";
import Dialog from "@material-ui/core/Dialog";
import { Card, CardBody } from "material-dashboard-react/components/index";
import { Row } from "antd";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "antd";
import { MdClose } from "react-icons/md";

import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import { multiResolved } from "api/alerts";

const style = () => ({
  close: {
    cursor: "pointer",
    color: "black",
    background: "#e1e1e1",
    border: "1px solid #e1e1e1",
    "&:hover": {
      background: "#f73232",
    },
  },
});

class CRUD extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resolveButton: true,
      open: false,
      data: false,
    };
  }

  handleResolved = (remark_message) => {
    const {
      selectedRowKeys,
      handleSubmitSuccess,
      handleClose,
      ResolveButton,
    } = this.props;
    let requestValue = {
      ids: selectedRowKeys,
      remarks: remark_message,
    };
    multiResolved(requestValue)
      .then(() => {
        handleSubmitSuccess();
        success("Updated Successfully..!");
        handleClose();
        ResolveButton();
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  render() {
    const { handleClose } = this.props;
    const { handleResolved } = this;
    const functionalProps = {
      handleResolved,
    };
    return (
      <React.Fragment>
        <Dialog
          open
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              overflow: "visible",
              minWidth: "35%",
              maxWidth: "65%",
              maxHeight: "65%",
            },
          }}
        >
          <Row xs={24} sm={24} md={24}>
            <Card style={{ margin: "0%" }}>
              <span>
                <Card
                  style={{
                    padding: "18px 25px 18px 25px",
                    borderBottom: "1px solid lightgray",
                    margin: 0,
                    backgroundColor: "#e53935",
                    borderRadius: "1px",
                  }}
                >
                  <Row style={{ display: "block" }}>
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontFamily: "serif",
                      }}
                    >
                      <h1
                        style={{
                          color: "white",
                          margin: "0px",
                        }}
                      >
                        Multi Resolve
                      </h1>

                      <Button
                        size="medium"
                        onClick={handleClose}
                        color="white"
                        display="flex"
                        style={{
                          marginTop: "6px",
                          marginRight: "-7px",
                          marginBottom: "0px",
                          display: "flex",
                        }}
                      >
                        <MdClose
                          style={{
                            fontSize: "25px",
                            cursor: "pointer",
                            fill: "#262609",
                            margin: "1px 0px",
                          }}
                          onClick={handleClose}
                        />
                      </Button>
                    </span>
                  </Row>
                </Card>
              </span>
              <CardBody>
                <Row>
                  <AlertUpdate {...functionalProps} />
                </Row>
              </CardBody>
            </Card>
          </Row>
        </Dialog>
      </React.Fragment>
    );
  }
}
export default withStyles(style)(CRUD);
