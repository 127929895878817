import React from "react";
import { titleize } from "helpers";
import { StatusComp } from "data/index";

const ScheduleTiles = ({ schedule_time }) => {
  const colors = {
    every_1_mins: "#ff5722",
    every_5_mins: "#40c4ff",
    every_30_mins: "#795548",
    start_of_day: "#ffd740",
  };
  const backgroundColor = colors[schedule_time] || "#bdbdbd";

  return (
    <span
      style={{
        color: "#fff",
        backgroundColor,
        padding: "6px 15px 5px",
        borderRadius: 50,
        marginLeft: 5,
      }}
    >
      {schedule_time ? titleize(schedule_time) : "Null"}
    </span>
  );
};

export const columns = [
  {
    title: "Name",
    dataIndex: "name",
    align: "center",
    render: (name) => `${titleize(name)}`,
  },
  {
    title: "Status",
    dataIndex: "status",
    align: "center",
    render: (status) => <StatusComp value={titleize(status)} />,
  },
  {
    title: "Schedule Time",
    dataIndex: "schedule_time",
    align: "center",
    render: (schedule_time) => <ScheduleTiles schedule_time={schedule_time} />,
  },
];
