import React, { Component } from "react";
import AppBar from "components/AppBar";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import { Header } from "components/detailsPage/header";
import { TabTable } from "components/detailsPage/tabTable";
import { getEmployeesData, removeEmployeesFace } from "api/employee";
import moment from "moment";
import { removeAccess } from "helpers";

const columns = [
  {
    title: "Entry time",
    dataIndex: "in_time",
    align: "center",
    render: (row) => moment(row).format("DD-MM-YYYY HH:MM"),
  },

  { title: "Device Types", dataIndex: "device_type", align: "center" },
  { title: "Mode of entry", dataIndex: "entry_mode", align: "center" },
];

class DetailsPage extends Component {
  constructor(props) {
    super(props);
    this.id = window.location.pathname.split("/")[2];
    this.handleChild = React.createRef();
    this.apiName = window.location.pathname.split("/")[1];
    this.state = {
      data: [],
      EmpData: [],
      tabName: "",
      imageData: [],
      remove_action: false,
    };
  }

  componentDidMount() {
    this.fetchDatas();
    this.removeAction();
  }

  fetchDatas = () => {
    getEmployeesData(this.id)
      .then(({ data }) => {
        this.setState({ data });
      })
      .catch((err) => {
        handleResponse(err);
      });
  };

  removeface = () => {
    const { data } = this.state;
    removeEmployeesFace(data.id)
      .then(({ message }) => {
        success(message);
        this.handleSubmitSuccess();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  removeAction = () => {
    if (removeAccess("employees")) {
      return this.setState({ remove_action: true });
    }
  };

  handleSubmitSuccess = () => {
    setTimeout(() => {
      this.fetchDatas();
    }, 6000);
  };

  render() {
    const { removeface } = this;
    const { data, remove_action } = this.state;
    const headerProps = {
      data,
      removeface,
      remove_action,
      details: true
    };

    const tabProps = {
      tabs: [
        {
          tab: "Employee Entry",
          key: "emp",
          data: data.employee_entries,
          column: columns,
          table: true,
        },
      ],
    };

    return (
      <>
        <AppBar />
        <Header {...headerProps} />
        <TabTable tabProps={tabProps} />
      </>
    );
  }
}

export default DetailsPage;
