import React, { Component } from "react";
import moment from "moment";
import AppBar from "components/AppBar";
import { columns } from "data/invitesVisitor";
import PaginationTable from "components/paginationTables/withAddEdit";
import { fetchEntryData, aggregation } from "api/inviteVisitor";
import { Row } from "antd";
import { withRouter } from "react-router-dom";
import { handleResponse } from "components/notifications/handleResponse";
import {
  debounce,
  setCookie,
  popCookie,
  createAccess,
  getCookie,
} from "helpers";
import CRUD from "components/form/Invites/CRUD";

class VisitorInvites extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.requestParams = this.initialRequestParams();
    this.state = {
      data: [],
      total_entries: 0,
      skeleton: true,
      formOpen: false,
      rowData: undefined,
    };
  }

  initialRequestParams = () => {
    return {
      fromTime: moment.utc(moment().startOf("week")).format(),
      toTime: moment.utc(moment().endOf("week")).format(),
      search: "",
      perPage: 25,
      page: 1,
    };
  };

  componentDidMount = () => {
    let scrollY = 0;
    let prevStateValues = popCookie("prevStateValues");
    let usePrevStateValues = popCookie("usePrevStateValues");

    if (usePrevStateValues && prevStateValues) {
      let data = JSON.parse(prevStateValues);
      if (data.currentUrl === this.currentUrl) {
        this.requestParams = data.requestParams;
        scrollY = data.scrollY;
        this.previousLoad = true;
      } else {
        this.requestParams = this.initialRequestParams();
      }
    } else {
      this.requestParams = this.initialRequestParams();
    }

    this.autoRefresh();
    this.fetchTableData();
    setTimeout(function() {
      window.scrollTo({ top: scrollY });
    }, 1000);
  };

  initialFilterState = () => {
    let prevStateValues = getCookie("prevStateValues");

    let phoneFilters = [];
    let companyNameFilters = [];
    let nameFilters = [];
    if (prevStateValues) {
      let data = JSON.parse(prevStateValues);

      phoneFilters = data.requestParams.phone;
      companyNameFilters = data.requestParams.companyName;
      nameFilters = data.requestParams.name;
    }
    return {
      phoneFilters,
      nameFilters,
      companyNameFilters,
    };
  };

  componentWillUnmount = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    let prevStateValues = JSON.stringify({
      scrollY: window.scrollY,
      requestParams: this.requestParams,
      currentUrl: this.currentUrl,
    });
    setCookie("prevStateValues", prevStateValues);
  };

  autoRefresh = () => {
    if (this.timer !== null) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.fetchTableData();
    this.timer = setTimeout(this.autoRefresh, 10000);
  };

  onDateChange = (from_time, to_time) => {
    this.requestParams.fromTime = from_time;
    this.requestParams.toTime = to_time;
    this.fetchTableData();
  };

  debounceSearch = debounce(() => {
    this.fetchTableData();
    this.setState(this.state);
  }, 10000);

  onChange = (name, value) => {
    this.requestParams[name] = value;
    this.requestParams.page = 1;
    this.setState(this.state);
    this.debounceSearch();
  };

  fetchTableData = () => {
    fetchEntryData(this.requestParams)
      .then(({ data, total_entries }) => {
        this.setState({
          data: data,
          total_entries: total_entries,
          skeleton: false,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  pagination = () => {
    const { requestParams, state } = this;
    return {
      total: state.total_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };

  handleTableChange = (pagination, filters) => {
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    this.requestParams.status = filters.status;
    this.fetchTableData();
  };

  onRowClick = (row) => {
    this.setState({ formOpen: true, rowData: row });
  };

  onClose = () => {
    this.setState({ formOpen: false });
  };

  inviteeDetails = (row) => {
    let invitee_id = row.id;
    let invitee_phone = row.phone;

    if (invitee_id && invitee_phone && row.status === "visited") {
      setCookie("visitor_entry_id", invitee_id);
      this.props.history.push({
        pathname: `/outsiders/${invitee_phone}`,
      });
    }
  };

  handleSubmitSuccess = () => {
    this.onClose();
    setTimeout(() => {
      this.fetchTableData();
    }, 1000);
  };

  createAction = () => {
    return createAccess("visitors");
  };

  getAggregation = async () => {
    let params = {
      fromTime: this.requestParams.fromTime,
      toTime: this.requestParams.toTime,
    };
    await aggregation(params)
      .then(({ data }) => {
        let company_name = data["company_name"]["company_name"]["buckets"];
        let name = data["name"]["name"]["buckets"];
        let phone = data["phone"]["phone"]["buckets"];

        let companyNameFilter = company_name.map((val) => ({
          label: val.key,
          value: val.key,
        }));

        let nameFilter = name.map((val) => ({
          label: val.key,
          value: val.key,
        }));

        let phoneFilter = phone.map((val) => ({
          label: val.key,
          value: val.key,
        }));

        this.setState({
          phoneFilter,
          nameFilter,
          companyNameFilter,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  debounceSearch = debounce(() => {
    this.fetchTableData();
    this.setState(this.state);
  }, 1000);

  filterChange = (obj) => {
    this.setState({
      loader: obj.loader,
      phoneFilters: obj.phone,
      companyNameFilters: obj.companyName,
      nameFilters: obj.name,
    });
    Object.keys(obj).filter((arr) => {
      return (this.requestParams[arr] = obj[arr]);
    });
    this.debounceSearch();
  };

  render() {
    const {
      data,
      skeleton,
      formOpen,
      rowData,
      phoneFilter,
      companyNameFilter,
      nameFilter,
      phoneFilters,
      companyNameFilters,
      modeFilters,
      nameFilters,
    } = this.state;
    const {
      onChange,
      onDateChange,
      fetchTableData,
      onClose,
      handleSubmitSuccess,
      getAggregation,
      filterChange,
    } = this;
    const tableProps = {
      tabColor: "info",
      title: "Invitation",
      subTitle: "Pre-register records can be monitored here",
      columns: columns,
      data: data,
      pagination: this.pagination(),
      handleTableChange: this.handleTableChange,
      triggerRefresh: fetchTableData,
      onRowClick: () => {},
      skeleton,
      dataValue: "add Invitation",
      createAction: this.createAction(),
      handleOnClick: () => {
        this.setState({ formOpen: true });
      },
    };

    const appBarProps = {
      fromTime: moment(this.requestParams.fromTime),
      toTime: moment(this.requestParams.toTime),
      search: this.requestParams.search,
      dateFilter: true,
      getAggregation,
      onChange,
      onDateChange,
      filter: true,
      FilterObj: {
        phoneFilter,
        companyNameFilter,
        nameFilter,
        phoneFilters,
        companyNameFilters,
        modeFilters,
        nameFilters,
        filterChange,
      },
    };
    const crudProps = {
      data: rowData,
      onCancel: onClose,
      handleSubmitSuccess,
      open: formOpen,
      tabColor: "info",
      titleText: "Invitation",
    };
    return (
      <React.Fragment>
        <AppBar {...appBarProps} />
        <Row>
          <PaginationTable {...tableProps} />
        </Row>
        <CRUD {...crudProps} />
      </React.Fragment>
    );
  }
}

export default withRouter(VisitorInvites);
