import React from "react";
import PropTypes from "prop-types";
import { FormControl } from "@material-ui/core/index";
import DateFnsUtils from "@date-io/date-fns";
import {
  DateTimePicker,
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
} from "@material-ui/pickers";

export function DateAndTimeInput(props) {
  let {
    onChange,
    width,
    value,
    helperText,
    format,
    labelText,
    required,
  } = props;
  const label = required ? `${labelText} *` : labelText;

  return (
    <>
      <FormControl
        fullWidth
        style={{ margin: 0, width: width ? width : "100%" }}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DateTimePicker
            disablePast
            label={label || "Select Date"}
            value={value || null}
            format={format || "dd-MMM-yyyy hh:mm a"}
            onChange={(date) => onChange(date)}
            helperText={helperText || ""}
          />
        </MuiPickersUtilsProvider>
      </FormControl>
      {required && (
        <input
          tabIndex={-1}
          autoComplete="off"
          style={{ opacity: 0, height: 0 }}
          value={value}
          required
        />
      )}
    </>
  );
}

export function DateInput(props) {
  let {
    onChange,
    width,
    value,
    helperText,
    format,
    labelText,
    required,
  } = props;
  const label = required ? `${labelText} *` : labelText;

  return (
    <>
      <FormControl
        fullWidth
        style={{ margin: 0, width: width ? width : "100%" }}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            disablePast
            label={label || "Select Date and Time"}
            value={value || null}
            format={format || "dd/MM/yyyy"}
            onChange={(date) => onChange(date)}
            helperText={helperText || ""}
          />
        </MuiPickersUtilsProvider>
      </FormControl>
      {required && (
        <input
          tabIndex={-1}
          autoComplete="off"
          style={{ opacity: 0, height: 0 }}
          value={value}
          required
        />
      )}
    </>
  );
}

export function TimeInput(props) {
  let {
    onChange,
    width,
    value,
    helperText,
    format,
    labelText,
    required,
  } = props;
  const label = required ? `${labelText} *` : labelText;
  return (
    <>
      <FormControl
        fullWidth
        style={{ margin: 0, width: width ? width : "100%" }}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <TimePicker
            label={label || "Select Time"}
            value={value || null}
            format={format || "hh:mm a"}
            onChange={(date) => onChange(date)}
            helperText={helperText || ""}
          />
        </MuiPickersUtilsProvider>
      </FormControl>

      {required && (
        <input
          tabIndex={-1}
          autoComplete="off"
          style={{ opacity: 0, height: 0 }}
          value={value}
          required
        />
      )}
    </>
  );
}

DateAndTimeInput.propTypes = {
  value: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
};

DateInput.propTypes = {
  value: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
};

TimeInput.propTypes = {
  value: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
};
