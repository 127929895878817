import React, { useEffect, useRef } from "react";
import { Table, Card } from "antd";
import { CustomButton } from "components/Button";

export const TableDatas = ({ tableProps }) => {
  const { curr_id } = tableProps;
  const lastProps = useRef({ curr_id });

  useEffect(() => {
    lastProps.current = tableProps.curr_id;
  }, [tableProps.curr_id]);

  let ids = curr_id;
  if (tableProps.selected_id) {
    ids = tableProps.selected_id === curr_id ? curr_id : tableProps.selected_id;
  }

  return (
    <div style={{ padding: "0 32px" }}>
      <Card
        style={{
          margin: 0,
          boxShadow:
            "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
        }}
      >
        {tableProps?.buttons && (
          <p style={{ margin: "12px 0px" }}>
            <CustomButton props={tableProps.props} />
          </p>
        )}
        <Table
          dataSource={tableProps.data}
          columns={tableProps.column}
          rowSelection={
            tableProps.onSelect && {
              selectedRowKeys: [ids],
              type: "radio",
              onSelect: (row) => tableProps.onSelect(row.id),
            }
          }
          rowKey={(row) => row.id}
        />
      </Card>
    </div>
  );
};
