import React, { Component } from "react";
import AppBar from "components/AppBar";
import PaginationTable from "components/paginationTables/withAddEdit";
import { Row } from "antd";
import { columns } from "data/employee";
import CRUDEmployee from "components/form/employee/CRUD";
import { updateAccess, createAccess, debounce, getCookie } from "helpers";
import { fetchEmployeesData, EmpAggregation } from "api/employee";
import { handleResponse } from "components/notifications/handleResponse";

class Employee extends Component {
  constructor(props) {
    super(props);
    this.requestParams = this.initialRequestParams();
    this.state = {
      data: [],
      formOpen: false,
      skeleton: true,
      total_entries: 0,
      ...this.initialFilterState(),
    };
  }

  initialRequestParams = () => {
    return {
      search: "",
      perPage: 25,
      page: 1,
    };
  };

  initialFilterState = () => {
    let prevStateValues = getCookie("prevStateValues");

    let phoneFilters = [];
    let nameFilters = [];
    if (prevStateValues) {
      let data = JSON.parse(prevStateValues);

      phoneFilters = data.requestParams.phone;
      nameFilters = data.requestParams.name;
    }
    return {
      phoneFilters,
      nameFilters,
    };
  };

  componentDidMount() {
    this.fetchApiDatas();
  }

  fetchApiDatas = () => {
    fetchEmployeesData(this.requestParams)
      .then(({ data, total_entries }) => {
        this.setState({ data, skeleton: false, total_entries });
      })
      .catch((err) => {
        handleResponse(err);
      });
  };

  pagination = () => {
    const {
      requestParams,
      state: { total_entries },
    } = this;
    return {
      total: total_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };

  handleTableChange = (pagination) => {
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    this.fetchApiDatas();
  };

  debounceSearch = debounce(() => {
    this.fetchApiDatas();
    this.setState(this.state);
  }, 1000);

  getAggregation = async () => {
    let params = {
      fromTime: this.requestParams.fromTime,
      toTime: this.requestParams.toTime,
    };
    await EmpAggregation(params)
      .then(({ data }) => {
        let name = data["name"]["name"]["buckets"];
        let phone = data["phone"]["phone"]["buckets"];

        let nameFilter = name.map((val) => ({
          label: val.key,
          value: val.key,
        }));

        let phoneFilter = phone.map((val) => ({
          label: val.key,
          value: val.key,
        }));

        this.setState({
          phoneFilter,
          nameFilter,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  onChange = (name, value) => {
    this.requestParams[name] = value;
    this.requestParams.page = 1;
    this.setState(this.state);
    this.debounceSearch();
  };

  createAction = () => {
    return createAccess("employees");
  };

  updateAction = () => {
    return updateAccess("employees");
  };

  editAction = (row) => {
    let access = this.updateAction();
    if (access) {
      this.setState({ formOpen: true, rowData: row });
    }
  };

  aboutAction = (row) => {
    this.props.history.push(`/employees/${row.phone}`);
  };

  onClose = () => {
    this.setState({ formOpen: false, rowData: undefined });
  };

  handleSubmitSuccess = () => {
    this.onClose();
    setTimeout(() => {
      this.fetchApiDatas();
    }, 7000);
  };

  filterChange = (obj) => {
    this.setState({
      loader: obj.loader,
      phoneFilters: obj.phone,
      nameFilters: obj.name,
    });
    Object.keys(obj).filter((arr) => {
      return (this.requestParams[arr] = obj[arr]);
    });
    this.debounceSearch();
  };

  render() {
    const {
      data,
      formOpen,
      rowData,
      skeleton,
      phoneFilter,
      nameFilter,
      phoneFilters,
      nameFilters,
    } = this.state;
    const {
      handleTableChange,
      editAction,
      aboutAction,
      onClose,
      handleSubmitSuccess,
      onChange,
      getAggregation,
      filterChange,
    } = this;

    let appBarProps = {
      search: this.requestParams.search,
      onChange,
      filter: true,
      getAggregation,
      FilterObj: {
        phoneFilter,
        nameFilter,
        phoneFilters,
        nameFilters,
        filterChange,
      },
    };

    const tableProps = {
      skeleton,
      title: "Employee",
      subTitle: "List of Employees",
      tabColor: "primary",
      columns: columns(editAction, aboutAction),
      data,
      pagination: this.pagination(),
      handleTableChange,
      onRowClick: () => {},
      handleOnClick: () => {
        this.setState({ formOpen: true });
      },
      dataValue: "Add Employee",
      createAction: this.createAction(),
      updateAction: this.updateAction(),
    };

    const CRUDProps = {
      data: rowData,
      onCancel: onClose,
      handleSubmitSuccess,
      open: formOpen,
      tabColor: "info",
      titleText: "Employee",
    };

    return (
      <>
        <AppBar {...appBarProps} />
        <Row>
          <PaginationTable {...tableProps} />
        </Row>
        <CRUDEmployee {...CRUDProps} />
      </>
    );
  }
}

export default Employee;
