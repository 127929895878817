import React from "react";
import {
  FaUserTie,
  FaRegChartBar,
  FaUserShield,
  FaMailBulk,
  FaAddressCard,
  FaUserAlt,
  FaUsers,
  FaFacebookMessenger 
} from "react-icons/fa";
import { GiEntryDoor } from "react-icons/gi";
import { AiFillAlert } from "react-icons/ai";
import {
  MdBlock,
  MdDashboard,
  MdLiveTv,
  MdImportantDevices,
  MdSchedule
} from "react-icons/md";
import {
  Home,
  BlackLists,
  VisitorEntry,
  VisitorInvites,
  Alerts,
  Analytics,
  Users,
  AlertScreen,
  AlertTypes,
  Devices,
  Contractorcard,
  Employee,
  EmployeeEntries,
  Outsiders,
  OutBox,
  Schedulers
} from "views";
import AlertOverview from "views/Alerts-Module/index";

import { VisitorEntries } from "data/VisitorEntries";

import { ParkingUnauthorized, OtherAlerts } from "config";
import { fetchUserRoles } from "api/users";
import { FaEllipsisV } from "react-icons/fa";
import { handleResponse } from "components/notifications/handleResponse";

const wrapHOC = (WrappedComponent) => {
  return <WrappedComponent style={{ fontSize: 22 }} />;
};

const Promise1 = new Promise((res) => {
  try {
    const data = JSON.parse(window.localStorage.getItem("user_roles"));
    if (!data || "object" !== typeof data) {
      res([false]);
    }
    res([true, data]);
  } catch (err) {
    res([false]);
  }
});

let parking_alert_type = [];

ParkingUnauthorized &&
  ParkingUnauthorized.type &&
  ParkingUnauthorized.type.forEach((i) => parking_alert_type.push(i));

let not_alert_group = [];
let not_alert_type = [];
if (ParkingUnauthorized.type?.length) {
  ParkingUnauthorized.type.forEach((type) => not_alert_type.push(type));
}
if (ParkingUnauthorized.group?.length) {
  ParkingUnauthorized.group.forEach((group) => not_alert_group.push(group));
}

const Others = {
  title: OtherAlerts.title,
  subtitle: OtherAlerts.subtitle,
  not_alert_group,
  not_alert_type,
  type: OtherAlerts.type
};

export const filteredMenu = async () => {
  try {
    let [status, userRoles] = await Promise1;
    if (!status) {
      await fetchUserRoles()
        .then(({ data }) => {
          userRoles = data;
          window.localStorage.setItem("user_roles", JSON.stringify(userRoles));
        })
        .catch(async (error) => {
          handleResponse(error);
        });
    }

    let result = [...menuGroups];

    menuGroups.map((menuGroup, i) => {
      let yy = menuGroup?.children.filter((menus, j) => {
        let xx = menus?.children?.filter(
          (menu) =>
            !(menu?.show === false) &&
            Object.keys(menu.permissions).every(
              (permission) =>
                Object.keys(userRoles).includes(permission) &&
                menu.permissions[permission].every((sub_permission) =>
                  userRoles[permission].includes(sub_permission)
                )
            )
        );
        result[i].children[j].children = xx;
        return !xx.length ? false : xx;
      });

      result[i].children = yy;
      return !yy.length ? false : yy;
    });

    result = result.filter((x) => !!x.children.length);
    return result;
  } catch (err) {
    console.log(err);
  }
};

const menuGroups = [
  {
    name: "Entry Management",
    index: 1,
    children: [
      {
        name: "Overview",
        children: [
          {
            index: "1-0",
            name: "Dashboard",
            icon: wrapHOC(MdDashboard),
            url: () => "/bms-dashboard",
            match_url: "/bms-dashboard",
            render: (props) => <Home {...props} />,
            permissions: {
              "api/v1/employees": ["tiles"],
            },
            slideShow: true,
            show: true,
          },
        ],
      },
      {
        name: "Entry Logs",
        children: [
          {
            index: "1-3",
            name: "Visitor Entries",
            icon: wrapHOC(FaUserAlt),
            url: () => "/visitor-logs",
            match_url: "/visitor-logs",
            render: (props) => (
              <VisitorEntry {...props} childProps={VisitorEntries} />
            ),
            permissions: {
              "api/v1/visits": ["index"],
            },
            slideShow: true,
            show: true,
          },
          {
            index: "3-9",
            name: "Employee Entries",
            icon: wrapHOC(GiEntryDoor),
            url: () => `/employees-logs`,
            match_url: "/employees-logs",
            render: (props) => <EmployeeEntries {...props} />,
            permissions: {
              "api/v1/employee_entries": ["index"],
            },
            slideShow: true,
            show: true,
          },
          {
            index: "1-7",
            name: "Invitation",
            icon: wrapHOC(FaMailBulk),
            url: () => "/invitations",
            match_url: "/invitations",
            render: (props) => <VisitorInvites {...props} />,
            permissions: {
              "api/v1/visitors": ["index"],
            },
            slideShow: true,
            show: true,
          },

          {
            index: "2-1",
            name: "Visitors",
            icon: wrapHOC(FaUsers),
            url: () => "/visitors",
            match_url: "/visitors",
            render: (props) => <Outsiders {...props} />,
            permissions: {
              "api/v1/visitors": ["index"],
            },
            slideShow: true,
          },
          {
            index: "3-11",
            name: "Employees",
            icon: wrapHOC(FaUserTie),
            url: () => `/employees`,
            match_url: "/employees",
            render: (props) => <Employee {...props} />,
            permissions: {
              "api/v1/employees": ["index"],
            },
            slideShow: true,
            show: true,
          },
        ],
      },

      {
        name: "Analytics",
        children: [
          {
            index: "1-9",
            name: "Analytics",
            icon: wrapHOC(FaRegChartBar),
            url: () => "/analytics",
            match_url: "/analytics",
            render: (props) => <Analytics {...props} />,
            permissions: {
              "api/v1/cameras": ["index"],
              "api/v1/vehicle_entries": ["traffic_flow"],
            },
            slideShow: true,
            show: false,
          },
        ],
      },
    ],
  },
  {
    name: "Alert Management",
    index: 2,
    children: [
      {
        name: "Alerts-Module",
        children: [
          {
            index: "2-0",
            name: "Presentation",
            icon: wrapHOC(MdLiveTv),
            url: () => "/alerts/overview",
            match_url: "/alerts/overview",
            render: (props) => <AlertOverview {...props} />,
            permissions: {
              "api/v1/alerts": ["index", "aggs"],
              "api/v1/alert_types": ["index"],
              "api/v1/presentations": ["index"],
            },
            slideShow: false,
            show: ParkingUnauthorized.show,
          },
          {
            index: "2-6",
            name: "Others",
            icon: wrapHOC(FaEllipsisV),
            url: () => "/alerts/others",
            match_url: "/alerts/others",
            render: (props) => <Alerts {...props} alertProps={Others} />,
            permissions: {
              "api/v1/alerts": ["index"],
              "api/v1/alert_types": ["index"],
            },
            slideShow: true,
            show: OtherAlerts.show,
          },
        ],
      },
    ],
  },

  {
    name: "Master DB",
    index: 3,
    children: [
      {
        name: "Database",
        children: [
          {
            index: "3-4",
            name: "BlackList",
            icon: wrapHOC(MdBlock),
            url: () => "/blacklists",
            match_url: "/blacklists",
            render: (props) => <BlackLists {...props} />,
            permissions: {
              "api/v1/blacklists": ["index"],
            },
            slideShow: true,
            show: false,
          },

          {
            index: "3-5",
            name: "Contractor Cards",
            icon: wrapHOC(FaAddressCard),
            url: () => "/visitor-entries/contractor-card",
            match_url: "/visitor-entries/contractor-card",
            render: (props) => <Contractorcard {...props} />,
            permissions: {
              "api/v1/contractor_cards": ["index"],
            },
            slideShow: true,
            show: false,
          },
        ],
      },
    ],
  },

  {
    name: "Admin Panel",
    index: 4,
    children: [
      {
        name: "User Management",
        children: [
          {
            index: "4-0",
            name: "Users",
            icon: wrapHOC(FaUserShield),
            url: () => "/users",
            match_url: "/users",
            render: (props) => <Users {...props} />,
            permissions: {
              "api/v1/users": ["index"],
            },
            slideShow: true,
          },
          {
            index: "4-1",
            name: "Devices",
            icon: wrapHOC(MdImportantDevices),
            url: () => `/devices`,
            match_url: "/devices",
            render: (props) => <Devices {...props} />,
            show: true,
            permissions: {
              "api/v1/cameras": ["index"],
              "api/v1/kiosks": ["index"],
            },
            slideShow: true,
          },
          {
            index: "4-2",
            name: "Schedulers",
            icon: wrapHOC(MdSchedule),
            url: () => `/schedulers`,
            match_url: "/schedulers",
            render: (props) => <Schedulers {...props} />,
            permissions: {
              "api/v1/schedulers": ["index"],
            },
            slideShow: true,
            show: true,
          },
          {
            index: "4-3",
            name: "OutBox",
            icon: wrapHOC(FaFacebookMessenger),
            url: () => `/out-box`,
            match_url: "/out-box",
            render: (props) => <OutBox {...props} />,
            show: true,
            permissions: {
              "api/v1/sent_alerts": ["index"]
            },
            slideShow: true,
          },
          {
            index: "4-4",
            name: "Alert Types",
            icon: wrapHOC(AiFillAlert),
            url: () => `/alert-types`,
            match_url: "/alert-types",
            render: (props) => <AlertTypes {...props} />,
            permissions: {
              "api/v1/alert_types": ["index"],
            },
            slideShow: true,
          },
          {
            index: "4-5",
            name: "Alert Screen",
            icon: wrapHOC(MdLiveTv),
            url: () => `/presentations`,
            match_url: "/presentations",
            render: (props) => <AlertScreen {...props} />,
            permissions: {
              "api/v1/presentations": ["index", "update"],
            },
            slideShow: true,
          },
        ],
      },
    ],
  },
];
