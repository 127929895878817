import React, { Component } from "react";
import moment from "moment";
import AppBar from "components/AppBar";
import PaginationTable from "components/paginationTables/withRefreshDownload";
import { fetchVisitsData, aggregationVisits } from "api/inviteVisitor";
import { Row } from "antd";
import { setCookie, popCookie, debounce, getCookie } from "helpers";
import { withRouter } from "react-router-dom";
import { handleResponse } from "components/notifications/handleResponse";

class VisitorEntry extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.requestParams = {};
    this.currentUrl = this.props.history.location.pathname;
    this.state = {
      data: [],
      total_entries: 0,
      skeleton: true,
      ...this.initialFilterState(),
      loader: false,
      page: 1,
    };
  }

  initialRequestParams = () => {
    return {
      fromTime: moment
        .utc(
          moment()
            .subtract(7, "days")
            .startOf("day")
        )
        .format(),
      toTime: moment.utc(moment().endOf("day")).format(),
      search: "",
      perPage: 25,
      page: 1,
    };
  };

  initialFilterState = () => {
    let prevStateValues = getCookie("prevStateValues");

    let phoneFilters = [];
    let companyNameFilters = [];
    let nameFilters = [];
    let statusFilters = [];
    if (prevStateValues) {
      let data = JSON.parse(prevStateValues);
      phoneFilters = data.requestParams.phone;
      companyNameFilters = data.requestParams.companyName;
      nameFilters = data.requestParams.name;
      statusFilters = data.requestParams.status;
    }
    return {
      phoneFilters,
      nameFilters,
      companyNameFilters,
      statusFilters,
    };
  };

  componentDidMount = () => {
    let scrollY = 0;
    let prevStateValues = popCookie("prevStateValues");
    let usePrevStateValues = popCookie("usePrevStateValues");

    if (usePrevStateValues && prevStateValues) {
      let data = JSON.parse(prevStateValues);
      if (data.currentUrl === this.currentUrl) {
        this.requestParams = data.requestParams;
        scrollY = data.scrollY;
        // this.previousLoad = true;
      } else {
        this.requestParams = this.initialRequestParams();
      }
    } else {
      this.requestParams = this.initialRequestParams();
    }
    this.autoRefresh();

    setTimeout(function() {
      window.scrollTo({ top: scrollY });
    }, 1000);
  };

  componentWillUnmount = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }

    let prevStateValues = JSON.stringify({
      scrollY: window.scrollY,
      requestParams: this.requestParams,
      currentUrl: this.currentUrl,
    });
    setCookie("prevStateValues", prevStateValues);
  };

  autoRefresh = () => {
    if (this.timer !== null) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.fetchTableData();
    this.timer = setTimeout(this.autoRefresh, 10000);
  };

  onDateChange = (from_time, to_time, loader) => {
    this.requestParams.fromTime = from_time;
    this.requestParams.toTime = to_time;
    this.fetchTableData();
    this.setState({ loader: loader });
  };

  debounceSearch = debounce(() => {
    this.fetchTableData();
    this.setState(this.state);
  }, 1000);

  onChange = (name, value) => {
    this.requestParams[name] = value;
    this.requestParams.page = 1;
    this.setState(this.state);
    this.debounceSearch();
  };

  fetchTableData = async () => {
    await fetchVisitsData(this.requestParams)
      .then(({ data, total_entries }) => {
        this.setState({
          data: data,
          total_entries: total_entries,
          skeleton: false,
          loader: false,
          page: 1,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  getAggregation = async () => {
    let params = {
      fromTime: this.requestParams.fromTime,
      toTime: this.requestParams.toTime,
    };
    await aggregationVisits(params)
      .then(({ data }) => {
        let company_name =
          data["visitor_details.company_name"]["visitor_details.company_name"]["buckets"] || [];
        let name = data["visitor_details.name"]?.["visitor_details.name"]?.["buckets"] || [];
        let phone = data["visitor_details.phone"]?.["visitor_details.phone"]?.["buckets"] || [];
        let status = data?.["status"]?.["status"]?.["buckets"] || [];

        let companyNameFilter = company_name.map((val) => ({
          label: val?.key,
          value: val?.key,
        }));

        let nameFilter = name.map((val) => ({
          label: val.key,
          value: val.key,
        }));

        let phoneFilter = phone.map((val) => ({
          label: val.key,
          value: val.key,
        }));

        let statusFilter = status.map((val) => ({
          label: val.key,
          value: val.key,
        }));

        this.setState({
          phoneFilter,
          nameFilter,
          companyNameFilter,
          statusFilter,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };
  pagination = () => {
    const { requestParams, state } = this;
    return {
      total: state.total_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };

  handleTableChange = (pagination) => {
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    this.requestParams.visitor_type = this.props.childProps.enumerable;
    this.fetchTableData();
  };

  onRowClick = (data) => {
    let visitor_id = data?.id,
      phone = data?.phone;
    if (phone) {
      setCookie("visitor_entry_id", visitor_id);
      this.props.history.push({
        pathname: `/outsiders/${phone}`,
      });
    }
  };

  filterChange = (obj) => {
    this.setState({
      loader: obj.loader,
      phoneFilters: obj.phone,
      companyNameFilters: obj.companyName,
      nameFilters: obj.name,
      statusFilters: obj.status,
    });
    Object.keys(obj).filter((arr) => {
      return (this.requestParams[arr] = obj[arr]);
    });
    this.debounceSearch();
  };

  render() {
    let { childProps } = this.props;
    const {
      data,
      skeleton,
      loading,
      loader,
      phoneFilter,
      statusFilter,
      companyNameFilter,
      nameFilter,
      phoneFilters,
      statusFilters,
      companyNameFilters,
      modeFilters,
      nameFilters,
    } = this.state;

    const {
      onChange,
      onDateChange,
      fetchTableData,
      getAggregation,
      onRowClick,
      filterChange,
    } = this;

    const tableProps = {
      ...childProps,
      data: data,
      pagination: this.pagination(),
      handleTableChange: this.handleTableChange,
      triggerRefresh: fetchTableData,
      onRowClick: onRowClick,
      skeleton,
      loading,
      loader,
    };

    const appBarProps = {
      fromTime: moment(this.requestParams.fromTime),
      toTime: moment(this.requestParams.toTime),
      search: this.requestParams.search,
      dateFilter: true,
      searchOpen: false,
      filter: true,
      onChange,
      onDateChange,
      getAggregation,
      FilterObj: {
        phoneFilter,
        companyNameFilter,
        nameFilter,
        statusFilter,
        phoneFilters,
        companyNameFilters,
        modeFilters,
        statusFilters,
        nameFilters,
        filterChange,
      },
    };

    return (
      <React.Fragment>
        <AppBar {...appBarProps} />{" "}
        <Row>
          <PaginationTable {...tableProps} />{" "}
        </Row>{" "}
      </React.Fragment>
    );
  }
}

export default withRouter(VisitorEntry);
