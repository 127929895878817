import * as React from "react";

const Resolve = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: "10px",
      }}
    >
      <svg width={25} height={25} viewBox="0 0 101 263" fill="none">
        <path
          d="M6.68 40.195c-3.573 19.254 8.203 37.67 27.035 44.972l.294.114.001.295.18 92.566.001.298-.3.114c-13.644 5.164-24.26 16.358-27.138 30.68-3.075 15.322 3.486 30.22 15.734 39.447l.002.002.002.001.009.007.01.008.028.021.158.115c.14.101.35.249.626.436.552.373 1.369.902 2.422 1.516a54.952 54.952 0 008.923 4.16c7.531 2.722 17.899 4.603 29.311 1.279M6.68 40.195C9.545 24.768 22.562 12.996 36.07 8l-6.23 33.593-.046.245.203.164L48.87 57.175l.223.18.275-.102 23.276-8.573.251-.092.046-.246 6.159-33.19C93.954 24.68 97.98 38.862 95.16 54.05 92.7 67.31 83.73 77.99 71.776 83.768l-.258.125v.27l.185 95.66v.267l.258.125c16.905 8.208 26.835 25.811 23.183 44.006-3.152 15.7-15.656 27.49-31.166 32.005M6.68 40.195l-.477-.075M63.98 256.226l.145.423m11.832-38.571l.114-.204-.114-.203L64.06 196.42l-.135-.241H39.536l-.135.241-11.898 21.251-.113.203.113.204 11.898 21.251.135.241h24.388l.135-.241 11.897-21.251z"
          stroke="#fff"
          strokeWidth={15}
        />
      </svg>
    </div>
  );
};

export default Resolve;
