import React from "react";
import { presence } from "helpers";
import { StatusTile } from "./statusTile";

export const columns = [
  {
    title: "Name",
    dataIndex: "name",
    render: (name) => `${presence(name)}`,
    align: "center",
  },
  {
    title: "Phone",
    render: (row) =>
      `${row.country_code ? "+" + row?.country_code : ""} ${presence(
        row.phone
      )}`,
    align: "center",
  },
  {
    title: "Email",
    dataIndex: "email",
    render: (email) => `${presence(email)}`,
    align: "center",
  },
  {
    title: "Company Name",
    dataIndex: "company_name",
    render: (company_name) => `${presence(company_name)}`,
    align: "center",
  },
  {
    title: "Visited status",
    dataIndex: "status",
    render: (status) => {
      return <StatusTile role_group={status && presence(status)} />;
    },
    align: "center",
  },
];
