import React, { Component } from "react";
import { Drawer, Row, Col } from "antd";
import UploadFile from "views/UploadFile";
import FormHandle from "components/form/form";
import { CustomButton } from "components/Button";
import { createKioskWorkflow, createVizitinWorkflow } from "api/workflow";
import {
  handleResponse,
  success,
  warn,
} from "components/notifications/handleResponse";

class WorkflowDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  handleChange = (e) => {
    const { data } = this.state;
    const { name, value } = e.target;
    this.setState({ data: { ...data, [name]: value } });
  };

  onSubmit = () => {
    const {
      deviceData,
      handleChild,
      apiName,
      onCancel,
      fetchWorkflow,
    } = this.props;

    const fileData = handleChild.current.requestParams();
    let id = deviceData.id;

    if (!fileData) {
      warn("Please Select the file");
      return;
    }
    const {
      data: { name, version },
    } = this.state;

    if (name && version) {
      const form = new FormData();
      form.append("file", fileData);
      form.append("workflow[name]", name);
      form.append("workflow[version]", version);
      let action =
        apiName === "kiosk" ? createKioskWorkflow : createVizitinWorkflow;

      action(id, form)
        .then(({ data }) => {
          this.setState({
            selected_id: data.id,
            current_workflow_id: data.id,
          });
          success("Workflow uploaded..!!!");
          onCancel("visible");
          fetchWorkflow();
        })
        .catch((error) => {
          handleResponse(error);
        });
    } else {
      warn("Please Enter the fields");
    }
  };

  render() {
    const {
      handleChange,
      state: { data },
    } = this;
    const { onCancel, visible, handleChild } = this.props;

    let value = [
      {
        value: [
          {
            label: "Name",
            name: "name",
            onChange: handleChange,
            validation: true,
            type: "text",
            value: data.name,
            size: {
              short: 8,
              medium: 12,
              large: 12,
            },
            required: true,
          },
          {
            label: "Version",
            name: "version",
            onChange: handleChange,
            validation: true,
            type: "text",
            value: data.version,
            size: {
              short: 8,
              medium: 12,
              large: 12,
            },
            required: true,
          },
        ],
      },
    ];

    const btnprops = {
      btnProps: [
        { onClick: onCancel, name: "visible", btnLabel: "Cancel" },
        { btnLabel: "Submit", onClick: this.onSubmit },
      ],
    };

    return (
      <Drawer
        title={`Add Workflow`}
        width={450}
        closable={true}
        visible={visible}
        onClose={() => onCancel("visible")}
      >
        <Row>
          <FormHandle value={value} />
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} style={{ padding: "10px" }}>
            <div className="dropbox">
              <UploadFile
                ref={handleChild}
                accept={".json"}
                {...{
                  title: "",
                  uploadButton: false,
                }}
              />
            </div>
          </Col>
        </Row>
        <CustomButton props={btnprops} />
      </Drawer>
    );
  }
}
export default WorkflowDrawer;
