import React, { Component } from "react";
import { Table } from "antd";
import Skeleton from "components/Skeleton";

class TableBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loader: false,
    };
  }

  render() {
    const {
      columns,
      data,
      pagination,
      handleTableChange,
      onRowClick,
      skeleton,
      loading,
      loader,
      rowSelection,
      multiResolveCall,
      selectedRowKeys
    } = this.props;

    const rowSelectKeys = {
      selectedRowKeys,
      onChange: multiResolveCall,
      getCheckboxProps: (record) => ({
        disabled: record.status === "Resolved",
        name: record.name,
      }),
    };

    return (
      <div
        style={{
          overflowX: "auto",
          width: "100%",
        }}
      >
        {skeleton ? (
          <Skeleton />
        ) : (
          <Table
            columns={columns}
            onRow={(record) => {
              return {
                onClick: () => {
                  onRowClick(record);
                },
              };
            }}
            rowKey={(record) => record.id}
            rowSelection={rowSelection ? rowSelectKeys : false}
            dataSource={data}
            onChange={handleTableChange}
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} results`,
              showSizeChanger: true,
              pageSizeOptions: ["10", "25", "50", "100"],
              ...pagination,
            }}
            loading={loading || loader}
          />
        )}
      </div>
    );
  }
}

export default TableBody;
