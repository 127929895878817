import React from "react";
import moment from "moment";
import { presence, titleize, timeDiff } from "helpers";
import { StatusComp } from "data/index";
import { roleType } from "config";

const colors = {
  "Super Admin": "#e91e63",
  Admin: "#673ab7",
  User: "#00e859",
  Demo: "#c2b500",
  Viewer: "#0400fc",
  All: "#911300",
  Resident: "#5d4037",
  Contractor: "#673ab7",
  Delivery: "#00e859",
  Guest: "#0400fc",
  Created: "#c2b500",
  Invited: "#911300",
  Visited: "#5d4037",
  Expired: "#e91e63",
  Approved: "#800000",
  Sending: "#FF8C00",
  Delivered: "#008B8B",
  Cancelled: "#977600",
  Blocked: "#181616",
  "Partially Visited": "#46b2c7",
  Owner: "#e32828",
  Tenant: "green",
  Left: "#2196f3",
  Right: "#673ab7",
  created: "#c2b500",
  invited: "#0400fc",
  visited: "#00e859",
  expired: "#e91e63",
  partially_visited: "#00e859",
  in: "#00e859",
  out: "#e91e63",
};

export const StatusTile = ({ role_group }) => {
  const backgroundColor = colors[role_group];
  const textColor = role_group === "-" ? "#000" : "#fff";
  return (
    <React.Fragment>
      <div
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        <div>
          <span
            style={{
              color: textColor,
              backgroundColor,
              padding: "6px 15px 5px",
              borderRadius: 50,
              marginLeft: 5,
              whiteSpace: "nowrap",
              marginTop: "4px",
              marginBottom: "4px",
            }}
          >
            {titleize(role_group)}
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export const RolesTiles = ({ roles }) => {
  return (
    <React.Fragment>
      <div
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        {roles?.map((role, i) => {
          const backgroundColor = colors[role.name] || "#9c27b0";

          return (
            <span
              key={i}
              style={{
                color: "#fff",
                backgroundColor,
                padding: "6px 15px 5px",
                borderRadius: 50,
                marginLeft: 5,
                whiteSpace: "nowrap",
                marginTop: "4px",
                marginBottom: "4px",
              }}
            >
              {role.name}
            </span>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export const columns = [
  {
    title: "Name",
    align: "center",
    dataIndex: "first_name",
    render: (first_name, { last_name }) =>
      `${presence(first_name)} ${last_name || ""}`,
    sorter: true,
  },
  {
    title: "Phone",
    dataIndex: "phone",
    align: "center",
    render: (phone) => `${presence(phone)}`,
  },
  {
    title: "Email",
    dataIndex: "email",
    align: "center",
    render: (email) => `${presence(email)}`,
  },
  {
    title: "Role",
    dataIndex: "roles",
    align: "center",
    render: (roles) => <RolesTiles roles={roles} />,
    width: "25%",
  },
  {
    title: "Last Login",
    dataIndex: "last_login",
    align: "center",
    render: (last_login) => `${timeDiff(moment(), last_login)}`,
    sorter: true,
  },
  {
    title: "Status",
    dataIndex: "status",
    align: "center",
    render: (status) => <StatusComp value={titleize(status)} />,
  },
];

//This below lines of code makes filter feature visible only if the super admin logins
let user_position = localStorage.getItem("roles");
if (user_position?.includes("Super Admin")) {
  columns[3] = {
    title: "Role",
    dataIndex: "roles",
    align: "center",
    render: (roles) => <RolesTiles roles={roles} />,
    width: "25%",
    filterMultiple: false,
    defaultFilteredValue: ["User"],
    filters: roleType.map((role) => ({ text: role, value: role })),
  };
}
