import React from "react";
import { Button, Typography } from "antd";

export const CustomButton = ({ props }) => {
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      {props?.title && (
        <Typography.Title level={3} style={{ flexGrow: 1 }}>
          {props?.title}
        </Typography.Title>
      )}
      {props?.btnProps &&
        props.btnProps.map((arr, i) => {
          return (
            <div style={{ margin: "0px 10px 10px 0px" }} key={i}>
              <Button onClick={() => arr.onClick(arr.name)} disabled={arr.disabled}>
                {arr?.btnLabel}
              </Button>
            </div>
          );
        })}
    </div>
  );
};
