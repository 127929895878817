import React from "react";
import { Modal, Row, DatePicker, Button, Form, Checkbox } from "antd";
import moment from "moment";
import Select from "react-select";

const { RangePicker } = DatePicker;

class FilterCard extends React.Component {
  constructor(props) {
    super(props);
    const fromTime =
      this.props.fromTime &&
      moment(new Date(this.props.fromTime), "YYYY-MM-DD");
    const toTime =
      this.props.toTime && moment(new Date(this.props.toTime), "YYYY-MM-DD");
    this.state = {
      visible: true,
      data: {
        status: this.props.status,
        fromTime,
        toTime,
        alert_type: this.props.alert_type,
        page: this.props.page,
        loader: true,
      },
    };
  }

  handleCancel = () => {
    this.props.toggleAlertFilterShow();
  };

  onChange = (e, date) => {
    let data = { ...this.state.data };
    const dateFormat = "DD-MM-YYYY";
    data["fromTime"] = moment
      .utc(moment(date[0], dateFormat).startOf("date"))
      .format();
    data["toTime"] = moment
      .utc(moment(date[1], dateFormat).endOf("date"))
      .format();
    this.setState({ data });
  };

  handleOk = (e) => {
    e.preventDefault();
    this.props.alertFilterChange(this.state.data);
    this.props.toggleAlertFilterShow();
  };

  handleReset = (e) => {
    e.preventDefault();
    let data = {
      status: [],
      alert_type: [],
      fromTime: "",
      toTime: "",
      page: 1,
      date:true
    };
    this.props.filterChange(data);
    this.props.toggleAlertFilterShow();
  };

  handleSelectChange = (name, value) => {
    let data = { ...this.state.data };
    data[name] = value;
    this.setState({ data });
  };

  render() {
    let options = ["Raised", "Resolved"];
    const dateFormat = "DD-MM-YYYY";
    let defaultValue = {};
    if (this.state.data.fromTime) {
      defaultValue = {
        defaultValue: [this.state.data.fromTime, this.state.data.toTime],
      };
    }
    return (
      <React.Fragment>
        {this.state.visible && (
          <Modal
            title="Filter Values"
            visible={this.state.visible}
            onCancel={this.handleCancel}
            bodyStyle={{ padding: "24px 24px 0px 24px" }}
            maskClosable={false}
            footer={[
              <Button
                key="submit"
                type="primary"
                onClick={this.handleReset}
                style={{
                  background: "#3b8bf7",
                  color: "white",
                  borderColor: "#3b8bf72",
                }}
              >
                Reset
              </Button>,
              <Button
                key="back"
                onClick={this.handleOk}
                style={{
                  background: "#3b8bf7",
                  color: "white",
                  borderColor: "#3b8bf7",
                }}
              >
                Apply
              </Button>,
            ]}
            width="30%"
          >
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <label
                style={{
                  fontSize: 11,
                  color: "rgba(0, 0, 0, 0.54)",
                  paddingRight: "5px",
                }}
              >
                Date :{" "}
              </label>
              <RangePicker
                {...defaultValue}
                format={dateFormat}
                onChange={this.onChange}
                bordered={false}
              />
            </Row>
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Form.Item>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      fontSize: 11,
                      color: "rgba(0, 0, 0, 0.54)",
                      paddingRight: "7px",
                    }}
                  >
                    Status :
                  </span>
                  <Checkbox.Group
                    options={options}
                    value={this.state.data.status}
                    name="status"
                    onChange={(value) => {
                      this.handleSelectChange("status", value);
                    }}
                  />
                </div>
              </Form.Item>
            </Row>
            <Row>
              {/* <Form.Item> */}
              <div
                style={{
                  margin: "-12px 0 -12px 0",
                  padding: "25px 0 25px",
                }}
              >
                <p
                  style={{
                    fontSize: 11,
                    margin: "-12px 0 0.3em",
                    color: "rgba(0, 0, 0, 0.54)",
                    padding: "1px 0 5px 0",
                  }}
                >
                  Alert Types
                </p>
                <Select
                  isMulti
                  value={this.state.data.alert_type}
                  options={this.props.default_alert_type}
                  onChange={(value) =>
                    this.handleSelectChange("alert_type", value)
                  }
                  closeMenuOnSelect={false}
                  isClearable={false}
                />
              </div>
              {/* </Form.Item> */}
            </Row>
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

export default FilterCard;
