import React from "react";
import { Card, CardBody } from "material-dashboard-react/components";
import { Button } from "antd";
import { titleize } from "helpers";
import { FaRegCopy } from "react-icons/fa";
import { copy } from "components/notifications/handleResponse";
import maleIcon from "assets/img/male.png";
import { TiUserDelete } from "react-icons/ti";

const copyPath = (path) => {
  let textField = document.createElement("textarea");
  textField.innerText = path;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
  copy("Token Copied");
};

export const Header = ({ data, removeface, resetOnClick, id, rbtn, remove_action, border, details }) => {
  return (
    <Card>
      <CardBody
        style={{
          padding: "20px 0px 0px",
          minHeight: "200px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            padding: "20px",
            height: "200px",
            flexDirection: details ? "row" : "column",
            flexWrap: "wrap",
            gap: "8px",
            justifyContent: details ? "space-between" : "center",
          }}
        >
          {details && (
            <>
              <div
                style={{ display: "flex", gap: "24px", alignItems: "center" }}
              >
                {data?.employee_image?.image_path ? (
                  <div className="image-cropper">
                    <img
                      src={data.employee_image.image_path}
                      alt="avatar"
                      className="profile-pic"
                    />
                  </div>
                ) : (
                  <img
                    src={maleIcon}
                    style={{
                      maxWidth: 110,
                      borderRadius: "50%",
                      margin: "0 15px",
                    }}
                    alt="resident-images"
                  />
                )}
                <div style={{ margin: "15px 0" }}>
                  <h1>{data.name || "-"}</h1>
                  <h4>
                    {"+"}
                    {data.country_code} {data.phone}
                  </h4>
                </div>
              </div>
              
              {remove_action && data?.employee_image?.image_path && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "1vh",
                  }}
                >
                  <Button
                    onClick={() => removeface()}
                    style={{ backgroundColor: "rgb(105 125 226)" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        color: "white",
                      }}
                    >
                      <TiUserDelete
                        style={{ margin: "6px 2px", fontSize: "16px" }}
                      />
                      <p style={{ margin: "3px 6px" }}>Remove Face</p>
                    </div>
                  </Button>
                </div>
              )}
            </>
          )} 

          {border &&(
            <>
              {data && data?.map((obj) => (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                      paddingBottom: "8px",
                    }}
                  >
                    <span style={{ fontWeight: "bolder" }}>{obj.name}</span>
                    <p style={{ margin: "7px 0px", fontSize: "15px" }}>
                      {titleize(obj.title)}
                    </p>
                  </div>
                  {obj.value && (
                    <BorderDesgin
                      datas={{ label: obj.label, value: obj.value }}
                    />
                  )}
                </>
              ))}

              {rbtn && (
                <>
                  <p />
                  <Button
                    onClick={() => resetOnClick(id)}
                    style={{ width: 118 }}
                    type="danger"
                  >
                    Reset
                  </Button>
                </>
              )}
            </>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export const BorderDesgin = ({ datas }) => {
  return (
    <div style={{ position: "relative", width: "20rem" }}>
      <label
        style={{
          position: "absolute",
          top: "-10px",
          left: "12px",
          background: "#fff",
          padding: "0px 6px",
        }}
      >
        {datas.label}
      </label>
      <div>
        <Button
          onClick={() => copyPath(datas.value)}
          value={datas.value}
          size="large"
          style={{
            position: "absolute",
            right: "0px",
            margin: "5px",
            fontSize: "23px",
            padding: "5px",
          }}
        >
          <FaRegCopy />
        </Button>
        <input
          value={datas.value}
          style={{
            width: "20rem",
            height: "50px",
            padding: "12px",
            border: "1px solid transparent",
            borderColor: "#af78788a",
            borderRadius: "4px",
          }}
        />
      </div>
    </div>
  );
};
