import React, { Component } from "react";
import ReactJson from "react-json-view";
import { Drawer } from "antd";

import { fetchCamera } from "api/camera";
import { fetchKiosk } from "api/kiosk";
import { fetchVizitin } from "api/vizitin";

import { handleResponse } from "components/notifications/handleResponse";
import AppBar from "components/AppBar";

import PaginationTable from "components/paginationTables/withAddEdit";
import { cameraColumns } from "data/camera";
import { kioskColumns } from "data/kiosk";
import { vizitinColumns } from "data/vizitin";

import CRUDCamera from "components/form/camera/CRUD";
import CRUDKiosk from "components/form/kiosk/CRUD";
import CRUDVizitin from "components/form/vizitin/CRUD";

import {
  getCookie,
  titleize,
  createAccess,
  updateAccess,
  checkAccess,
  showAccess,
} from "helpers";
import { purpose, vizitinFilter } from "config";

const kioskFilter = {
  VMS: "vms",
};
class Devices extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.requestParams = this.initialRequestParams();
    this.state = {
      cameradata: [],
      kioskdata: [],
      botList: [],
      vizitindata: [],
      softwareData: [],
      deviceData: undefined,
      formOpen: false,
      device: "cameras",
      showdrawer: false,
      skeleton: true,
      selectedCam: [],
      selectedKiosk: [],
      selectedVizitin: [],
      total_entries: 0,
      add_permission: false,
      update_permission: false,
      show_permission: false,
      workflow_permission: false,
    };
  }

  initialRequestParams = () => {
    return {
      perPage: 25,
      page: 1,
      search: "",
      type: purpose && Object.values(purpose),
      kiosk_type: kioskFilter && Object.values(kioskFilter),
      vizitin_type: vizitinFilter && Object.values(vizitinFilter),
    };
  };

  componentDidMount = () => {
    this.fetchCameraData();
  };

  fetchCameraData = async () => {
    this.createAction("cameras");
    this.updateAction("cameras");
    this.showAction("cameras");
    await fetchCamera(this.requestParams)
      .then(({ data, total_entries }) => {
        this.setState({
          cameradata: data,
          total_entries: total_entries,
          skeleton: false,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  fetchKioskData = async () => {
    await fetchKiosk(this.requestParams)
      .then(({ data, total_entries }) => {
        // let botList = data.map(ite => { return ite.bot_id })
        this.setState({
          kioskdata: data,
          total_entries: total_entries,
          skeleton: false,
          // botList: botList
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  fetchVizitinData = async () => {
    await fetchVizitin(this.requestParams)
      .then(({ data, total_entries }) => {
        this.setState({
          vizitindata: data,
          total_entries: total_entries,
          skeleton: false,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  handleSubmitSuccess = () => {
    const { device } = this.state;
    this.handleClose();
    this.timer = setTimeout(() => {
      switch (device) {
        case "cameras":
          return this.fetchCameraData();
        case "kiosks":
          this.fetchKioskData();
          break;
        case "vizitin":
          this.fetchVizitinData();
          break;
        default:
          break;
      }
    }, 1000);
  };

  handleClose = () => {
    this.setState({
      formOpen: false,
      deviceData: undefined,
    });
  };

  pagination = () => {
    const { requestParams, state } = this;
    return {
      total: state.total_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };

  handleTableChange = (pagination, filters) => {
    const { device } = this.state;
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    switch (device) {
      case "cameras":
        this.requestParams.type =
          filters.type && filters.type.length > 0 ? filters.type : "";
        this.setState({ selectedCam: this.requestParams.type });
        this.fetchCameraData();
        break;
      case "kiosks":
        this.requestParams.kiosk_type =
          filters.device_type && filters.device_type.length > 0
            ? filters.device_type
            : "";
        this.setState({ selectedKiosk: this.requestParams.kiosk_type });
        this.fetchKioskData();

        break;
      case "vizitins":
        this.requestParams.vizitin_type =
          filters.device_type && filters.device_type.length > 0
            ? filters.device_type
            : "";
        this.setState({ selectedVizitin: this.requestParams.vizitin_type });
        this.fetchVizitinData();
        break;
      default:
        break;
    }
  };

  tabChange = (e) => {
    this.setState({ device: e.value, skeleton: true });
    setTimeout(() => {
      switch (e.value) {
        case "cameras":
          return this.fetchCameraData();
        case "kiosks":
          this.createAction("kiosks");
          this.updateAction("kiosks");
          this.showAction("kiosks");
          this.workFlow("kiosks");
          this.fetchKioskData();
          break;
        case "vizitins":
          return this.fetchVizitinData();

        default:
          break;
      }
    }, 1000);
  };

  editDevice = (row) => {
    this.setState({ formOpen: true, deviceData: row });
  };

  aboutDevice = (row, value) => {
    if (value) {
      this.props.history.push({
        pathname: `/${value}/${row.mac_address}`,
        state: row,
      });
    } else {
      this.setState({ showdrawer: true, deviceData: row });
    }
  };

  createAction = (device) => {
    let access = createAccess(device);
    this.setState({ add_permission: access });
  };

  updateAction = (device) => {
    let access = updateAccess(device);
    this.setState({ update_permission: access });
  };

  showAction = (device) => {
    let access = showAccess(device);
    this.setState({ show_permission: access });
  };

  workFlow = (device) => {
    let access = checkAccess(device);
    this.setState({ workflow_permission: access });
  };

  render() {
    const {
      cameradata,
      kioskdata,
      vizitindata,
      deviceData,
      botList,
      formOpen,
      device,
      showdrawer,
      skeleton,
      softwareData,
      selectedCam,
      selectedVizitin,
      selectedKiosk,
      add_permission,
      update_permission,
      show_permission,
      workflow_permission,
    } = this.state;

    const {
      handleClose,
      handleSubmitSuccess,
      tabChange,
      editDevice,
      aboutDevice,
      workflowDevice,
      handleTableChange,
    } = this;

    const CRUDProps = {
      tabColor: "primary",
      titleText: "Camera",
      softwareData,
      data: deviceData,
      onCancel: handleClose,
      handleSubmitSuccess,
      open: formOpen,
      device,
    };

    const KioskCRUDProps = {
      tabColor: "primary",
      titleText: "Kiosk",
      softwareData,
      data: deviceData,
      onCancel: handleClose,
      handleSubmitSuccess,
      open: formOpen,
      device,
      botList,
    };

    const tableProps = {
      tabColor: "primary",
      title: "Devices",
      subTitle: "Devices here",
      handleOnClick: () => {
        this.setState({ formOpen: true });
      },
      dataValue: "Add Device",
      tabChange,
      createAction: add_permission,
    };

    const appBarProps = {
      condo: { name: titleize(getCookie("alias_name")) },
    };

    const cameraProps = {
      columns: cameraColumns(
        editDevice,
        aboutDevice,
        selectedCam,
        update_permission,
        show_permission
      ),
      cameradata,
      skeleton,
      pagination: this.pagination(),
      handleTableChange,
    };

    const kioskProps = {
      columns: kioskColumns(
        editDevice,
        aboutDevice,
        update_permission,
        show_permission,
        selectedKiosk,
        selectedVizitin
      ),
      kioskdata,
      skeleton,
      softwareData,
      pagination: this.pagination(),
      handleTableChange,
    };

    const vizitinProps = {
      columns: vizitinColumns(
        editDevice,
        aboutDevice,
        selectedVizitin,
        workflowDevice,
        update_permission,
        update_permission,
        show_permission,
        workflow_permission
      ),
      vizitindata,
      skeleton,
      softwareData,
      pagination: this.pagination(),
      handleTableChange,
    };

    return (
      <React.Fragment>
        <AppBar {...appBarProps} />
        <PaginationTable
          {...tableProps}
          cameraProps={cameraProps}
          kioskProps={kioskProps}
          vizitinProps={vizitinProps}
        />

        {device === "cameras" && <CRUDCamera {...CRUDProps} />}
        {device === "kiosks" && <CRUDKiosk {...KioskCRUDProps} />}
        {device === "vizitins" && <CRUDVizitin {...CRUDProps} />}

        {showdrawer && (
          <Drawer
            title="Device Details"
            width={550}
            visible={showdrawer}
            onClose={() =>
              this.setState({ showdrawer: false, deviceData: undefined })
            }
          >
            <ReactJson
              src={deviceData}
              displayObjectSize={false}
              displayDataTypes={false}
            />
          </Drawer>
        )}
      </React.Fragment>
    );
  }
}

export default Devices;
