import React from "react";

export const PrimaryFlag = () => {
  return (
    <svg
      width="18"
      height="25"
      viewBox="0 0 18 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3353 10.0586L17.9541 5.4478C18.0334 5.22212 18.0092 4.96641 17.8896 4.76519C17.77 4.56392 17.5706 4.44336 17.3573 4.44336H9.21442C8.85939 4.44336 8.57156 4.77129 8.57156 5.17578V11.7676H7.07157C6.71654 11.7676 6.42871 12.0955 6.42871 12.5V14.9414C6.42871 15.3459 6.71654 15.6738 7.07157 15.6738H17.3573C17.5706 15.6738 17.77 15.5533 17.8896 15.352C18.0092 15.1508 18.0334 14.8951 17.9541 14.6694L16.3353 10.0586Z"
        fill="#4CAF50"
      />
      <path
        d="M7.07144 15.6738C6.98864 15.6738 6.90511 15.6556 6.82557 15.6181C6.58535 15.5047 6.42871 15.2376 6.42871 14.9414V12.5C6.42871 12.0955 6.71654 11.7676 7.07157 11.7676H9.21442C9.47443 11.7676 9.70886 11.946 9.80833 12.2197C9.9078 12.4934 9.85282 12.8084 9.66896 13.0179L7.52611 15.4593C7.40315 15.5995 7.23871 15.6738 7.07144 15.6738Z"
        fill="#0F6812"
      />
      <path
        d="M9.21427 2.00195H0.642856C0.287828 2.00195 0 2.32988 0 2.73438V12.5C0 12.9045 0.287828 13.2324 0.642856 13.2324H9.21427C9.5693 13.2324 9.85713 12.9045 9.85713 12.5V2.73438C9.85713 2.32988 9.5693 2.00195 9.21427 2.00195Z"
        fill="#69D06D"
      />
      <path
        d="M0.642856 25C0.287828 25 0 24.6721 0 24.2676V0.732422C0 0.32793 0.287828 0 0.642856 0C0.997884 0 1.28571 0.32793 1.28571 0.732422V24.2676C1.28571 24.6721 0.997884 25 0.642856 25Z"
        fill="#566B74"
      />
    </svg>
  );
};

export const FaceRegistered = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8648 8.63806H12.5003H9.13574C7.25523 8.63806 8.94029 12.3154 5.75586 12.3154V13.7687C5.75586 14.0479 5.78659 14.3355 5.84522 14.6273C6.2892 16.8624 8.35259 19.3432 10.811 20.2549C12.0284 20.7058 12.9736 20.7058 14.1895 20.2549C16.6494 19.3432 18.7128 16.8624 19.1568 14.6273C19.214 14.3355 19.2447 14.0479 19.2447 13.7687V12.3154C16.0602 12.3153 17.7467 8.63806 15.8648 8.63806Z"
        fill="#FFECE4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5003 4.40515C9.12878 4.40515 5.75586 6.85109 5.75586 11.7415V12.3153H6.12165C8.61784 12.3153 7.39906 9.62227 9.13579 9.62227H12.5003H15.8649C17.603 9.62227 16.3828 12.3153 18.879 12.3153H19.2448V11.7415C19.2447 6.85104 15.8718 4.40515 12.5003 4.40515V4.40515Z"
        fill="#C38959"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1057 12.3154V13.7687C17.1057 14.0479 17.075 14.3355 17.0178 14.6273C16.5738 16.8624 14.5104 19.3432 12.0505 20.2549C11.8369 20.3344 11.6317 20.4001 11.4307 20.4517C12.3688 20.6919 13.187 20.6277 14.1893 20.2549C16.6492 19.3432 18.7126 16.8624 19.1566 14.6273C19.2138 14.3355 19.2445 14.0479 19.2445 13.7687V12.3154H18.8787C17.6041 12.3154 17.2984 11.6131 17.0736 10.9263C17.0945 11.1901 17.1057 11.4624 17.1057 11.7416V12.3154Z"
        fill="#FFDDCE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2445 11.7415C19.2445 6.85109 15.8716 4.40515 12.5 4.40515C12.1426 4.40515 11.7852 4.43309 11.4307 4.48892C13.8375 4.86308 16.0586 6.51183 16.8237 9.43664C16.9452 9.90153 17.0303 10.3972 17.0736 10.9263C17.2984 11.6131 17.6041 12.3154 18.8787 12.3154H19.2445V11.7415Z"
        fill="#B17A4A"
      />
      <path
        d="M22.5947 14.9949H2.40476C2.20172 14.9949 2.03711 14.8303 2.03711 14.6273C2.03711 14.4242 2.20172 14.2596 2.40476 14.2596H22.5947C22.7977 14.2596 22.9623 14.4242 22.9623 14.6273C22.9623 14.8303 22.7977 14.9949 22.5947 14.9949V14.9949Z"
        fill="#69D06D"
      />
      <path
        d="M0.368626 5.83235C0.165586 5.83235 0.000976562 5.66775 0.000976562 5.46471V1.15224C0.000976562 0.516903 0.517892 0 1.15319 0H5.46567C5.66871 0 5.83332 0.164605 5.83332 0.367641C5.83332 0.570677 5.66871 0.735282 5.46567 0.735282H1.15319C0.923287 0.735282 0.736227 0.922337 0.736227 1.15224V5.46476C0.736276 5.6678 0.571667 5.83235 0.368626 5.83235V5.83235Z"
        fill="#566B74"
      />
      <path
        d="M24.6317 5.83235C24.4286 5.83235 24.264 5.66775 24.264 5.46471V1.15224C24.264 0.922337 24.0776 0.735282 23.8485 0.735282H19.5346C19.3316 0.735282 19.167 0.570677 19.167 0.367641C19.167 0.164605 19.3316 0 19.5346 0H23.8485C24.4831 0 24.9993 0.516903 24.9993 1.15224V5.46476C24.9993 5.6678 24.8347 5.83235 24.6317 5.83235Z"
        fill="#566B74"
      />
      <path
        d="M5.46479 24.9991H1.15226C0.516915 24.9991 0 24.4829 0 23.8483V19.5344C0 19.3314 0.164609 19.1667 0.36765 19.1667C0.57069 19.1667 0.735299 19.3314 0.735299 19.5344V23.8483C0.735299 24.0774 0.92236 24.2639 1.15226 24.2639H5.46475C5.66779 24.2639 5.8324 24.4285 5.8324 24.6315C5.8324 24.8345 5.66784 24.9991 5.46479 24.9991V24.9991Z"
        fill="#566B74"
      />
      <path
        d="M23.8485 24.9991H19.5346C19.3316 24.9991 19.167 24.8345 19.167 24.6315C19.167 24.4285 19.3316 24.2639 19.5346 24.2639H23.8485C24.0776 24.2639 24.264 24.0774 24.264 23.8483V19.5344C24.264 19.3314 24.4286 19.1667 24.6317 19.1667C24.8347 19.1667 24.9993 19.3314 24.9993 19.5344V23.8483C24.9993 24.4829 24.4831 24.9991 23.8485 24.9991Z"
        fill="#566B74"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.60087 14.6272C2.60087 13.9975 2.0899 13.4866 1.45893 13.4866C0.829331 13.4866 0.318359 13.9975 0.318359 14.6272C0.318359 15.2582 0.829331 15.7678 1.45893 15.7678C2.08995 15.7678 2.60087 15.2582 2.60087 14.6272Z"
        fill="#0F6812"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.6819 14.6272C24.6819 13.9975 24.171 13.4866 23.54 13.4866C22.9104 13.4866 22.3994 13.9975 22.3994 14.6272C22.3994 15.2582 22.9104 15.7678 23.54 15.7678C24.171 15.7678 24.6819 15.2582 24.6819 14.6272V14.6272Z"
        fill="#0F6812"
      />
    </svg>
  );
};

export const CardRegistered = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.4111 0H15.37C14.9485 0.00109311 14.5444 0.16829 14.2454 0.465318L0.465517 14.2452C-0.155172 14.8661 -0.155172 15.8725 0.465517 16.4934L8.50661 24.5345C9.12749 25.1552 10.134 25.1552 10.7548 24.5345L24.5347 10.7547C24.8337 10.457 25.0012 10.052 25 9.63003V1.58998C25 0.712263 24.2888 0.000596244 23.4111 0ZM19.1712 8.47992C17.7076 8.47992 16.5212 7.2935 16.5212 5.82997C16.5212 4.36644 17.7076 3.17997 19.1712 3.17997C20.6347 3.17997 21.8211 4.36639 21.8211 5.82992C21.8211 7.29345 20.6347 8.47992 19.1712 8.47992Z"
        fill="#FFBF41"
      />
    </svg>
  );
};
