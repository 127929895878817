import makeRequest from ".";
import { headers } from "helpers";

function generateParams(params = {}) {
  let vizitinTypes = "";
  let status = "";

  if (params.vizitin_type && params.vizitin_type.length <= 1) {
    vizitinTypes = params.vizitin_type
      .map((value) => `&type[]=${value}`)
      .join("");
  }

  if (params?.status) {
    status = params.status.map((value) => `&status[]=${value}`).join("");
  }

  return `${vizitinTypes}${status}&page=${params.page ||
    1}&per_page=${params.perPage || 25}`;
}

export function fetchVizitin(requestParams) {
  return makeRequest({
    uri: `/api/v1/vizitins?${generateParams(requestParams)}`,
  });
}

export function createVizitin(requestBody) {
  return makeRequest({
    uri: `/api/v1/vizitins`,
    method: "POST",
    headers: headers(),
    body: JSON.stringify(requestBody),
  });
}

export function updateVizitin(requestBody, id) {
  return makeRequest({
    uri: `/api/v1/vizitins/${id}`,
    method: "PUT",
    headers: headers(),
    body: JSON.stringify(requestBody),
  });
}

export function fetchVizitinDetails(mac_adderss) {
  return makeRequest({
    uri: `/api/v1/vizitins/${mac_adderss}/details`,
  });
}

export function fetchVizitinImage(id, requestParams) {
  return makeRequest({
    uri: `/api/v1/visitors/${id}/face_id_images?${generateParams(
      requestParams
    )}`,
  });
}

export function VizitinRest(id) {
  return makeRequest({
    uri: `/api/v1/vizitins/${id}/reset`,
    method: "PUT",
  });
}