import React, { Component } from "react";
import Form from "./Form";
import { updateKiosk, createKiosk } from "api/kiosk";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import DialogForm from "hoc/dialogForm";

class CRUD extends Component {
  constructor(props) {
    super(props);
    this.id = undefined;
    this.timer = null;
    this.state = {
      data: {
        device_type: this.props.data?.device_type || "vms",
        status: "active",
        // selected_bots: "",
        timeout: "180",
        ...this.props.data,
      },
      open: false,
    };
  }

  handleChange = (e) => {
    const { data } = this.state;
    const { name, value } = e.target;
    this.setState({ data: { ...data, [name]: value } });
  };

  tabChange = (e) => {
    this.setState({
      device_type: e.target.value,
      name: "",
      mac_address: "",
      status: "",
      selected_bots: "",
      timeout: "",
      description: "",
    });
    this.setState({ [e.target.name]: e.target.value });
  };

  requestParams = () => {
    const {
      data: { selected_bots, ...rest },
    } = this.state;
    const { id } = this;

    return {
      kiosks: {
        id,
        ...rest,
        bot_id: selected_bots?.value,
      },
    };
  };

  onSubmit = () => {
    const {
      requestParams,
      props,
      state: { data },
    } = this;
    let requestValue = requestParams();
    const action = data.id ? updateKiosk : createKiosk;
    action(requestValue, data.id)
      .then((data) => {
        success(data.message);
        props.handleSubmitSuccess();
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  render() {
    const { device } = this.props;
    const { id, handleChange, tabChange } = this;
    const { data } = this.state;

    const props = {
      id,
      data,
      device,
      handleChange,
      tabChange,
    };
    return (
      <>
        <Form {...props} />
      </>
    );
  }
}
export default DialogForm("Kiosk", 620)(CRUD);
