import React from "react";
// import Charts from "components/charts/dashboardCharts";
import VehicleInOut from "views/Home/Sections/VehicleInOut";
import { FaPersonBooth } from "react-icons/fa";
import { IoMdAlert } from "react-icons/io";
import { EmployeesEntry, alertsRaised, VisitorEntry } from "api/dashboard";
import { columns as VisitorEntryColumns } from "data/vehicleInVisitor";
import { columns as alertsColumn } from "data/alertsRaised";

// export const VisitorType = () => {
//   return (
//     <Charts
//       bindto="type-of-visitors"
//       fetchFun={visitorTypes}
//       title="Types Of Visitors"
//       subtitle="Last 24 hours"
//       icon={<MdTimeline />}
//       chartType="pie"
//     />
//   );
// };

export const JustEnteredEmployee = () => {
  return (
    <VehicleInOut
      title="Just Entered"
      type="(Employee)"
      subtitle="Recent 5 Entries"
      color="primary"
      icon={<FaPersonBooth />}
      fetchUrl={EmployeesEntry}
      columns={VisitorEntryColumns}
      titleColor={"#964CAE"}
    />
  );
};

export const JustEnteredVisitor = () => {
  return (
    <VehicleInOut
      title="Just Entered"
      type="(Visitor)"
      subtitle="Recent 5 Entries"
      color="success"
      icon={<FaPersonBooth />}
      fetchUrl={VisitorEntry}
      columns={VisitorEntryColumns}
      titleColor={"#964CAE"}
    />
  );
};

export const RaisedAlerts = () => {
  return (
    <VehicleInOut
      title="Alerts Raised"
      subtitle="Recent 5 Raised Alerts"
      color="danger"
      icon={<IoMdAlert />}
      fetchUrl={alertsRaised}
      columns={alertsColumn}
      titleColor={"red"}
    />
  );
};
