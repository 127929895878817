import React, { Component } from "react";
import AppBar from "components/AppBar";
import PaginationTable from "components/paginationTables/withAddEdit";
import { fetchOutsidersData } from "api/outsiders";
import { columns } from "data/outsiders";
import CRUDOutsider from "components/form/outsiders/CRUD";
import { Row } from "antd";
import { handleResponse } from "components/notifications/handleResponse";
import { setCookie } from "helpers";
import { createAccess, updateAccess, debounce } from "helpers";

class Outsiders extends Component {
  constructor(props) {
    super(props);
    this.requestParams = this.initialRequestParams();
    this.state = {
      data: [],
      total_entries: 0,
      formOpen: false,
      rowData: undefined,
      skeleton: true,
    };
  }

  initialRequestParams = () => {
    return {
      search: "",
      perPage: 25,
      page: 1,
    };
  };
  componentDidMount() {
    this.fetchTableData();
  }

  flushTimer = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  };

  componentWillUnmount = () => {
    this.flushTimer();
  };

  debounceSearch = debounce(() => {
    this.fetchTableData();
    this.setState(this.state);
  }, 1000);

  onChange = (name, value) => {
    this.requestParams[name] = value;
    this.setState(this.state);
    this.debounceSearch();
  };

  fetchTableData = () => {
    fetchOutsidersData(this.requestParams)
      .then(({ data, total_entries }) => {
        this.setState({
          data: data,
          total_entries: total_entries,
          skeleton: false,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  handleSubmitSuccess = () => {
    this.onCancel();
    setTimeout(() => {
      this.fetchTableData();
    }, 1000);
  };

  onCancel = () => {
    this.setState({
      formOpen: false,
      rowData: undefined,
    });
  };

  pagination = () => {
    const { requestParams, state } = this;
    return {
      total: state.total_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };
  editOutsider = (row) => {
    this.setState({ formOpen: true, rowData: row });
  };
  outsiderHistory = (row) => {
    let visitor_entry_id = row.id,
      phone = row.phone;

    if (phone) {
      setCookie("visitor_entry_id", visitor_entry_id);
      this.props.history.push({
        pathname: `/outsiders/${phone}`,
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    this.requestParams.status = filters.status;
    let field = sorter && sorter.field;
    let order = sorter && sorter.order === "ascend" ? "asc" : "desc";
    this.requestParams.sort_field = field && `${field}`;
    this.requestParams.sort_order = order && `${order}`;
    this.fetchTableData();
  };

  createAction = () => {
    return createAccess("visitors");
  };

  updateAction = () => {
    return updateAccess("visitors");
  };

  render() {
    const { data, formOpen, rowData, skeleton } = this.state;
    const { onChange, onCancel, handleSubmitSuccess } = this;
    const appBarProps = {
      search: this.requestParams.search,
      searchOpen: false,
      onChange,
    };
    const tableProps = {
      tabColor: "danger",
      title: "Visitors",
      subTitle: "Create And Update Known Visitors Details Here",
      columns: columns(
        this.editOutsider,
        this.outsiderHistory,
        this.updateAction
      ),
      data: data,
      pagination: this.pagination(),
      handleTableChange: this.handleTableChange,
      onRowClick: () => {},
      handleOnClick: () => {
        this.setState({ formOpen: true });
      },
      dataValue: "ADD Visitors",
      skeleton,
      createAction: this.createAction(),
    };

    const CRUDOutsiderProps = {
      open: formOpen,
      tabColor: "danger",
      titleText: "Visitors",
      data: rowData,
      handleSubmitSuccess,
      onCancel,
      // showDelete: true,
      dateFilter: true,
    };
    return (

      <React.Fragment>


        <AppBar {...appBarProps} />
        <Row>
          <PaginationTable {...tableProps} />
        </Row>
        <CRUDOutsider {...CRUDOutsiderProps} />
      </React.Fragment>
    );
  }
}
export default Outsiders;
