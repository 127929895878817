import React, { Component } from "react";
import AppBar from "components/AppBar";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import { Header } from "components/detailsPage/header";
import { TableDatas } from "components/detailsPage/table";
import { fetchKioskDetails, fetchEmployeeImage } from "api/kiosk";
import { fetchVizitinDetails, fetchVizitinImage } from "api/vizitin";
import ViewJSON from "components/ViewJSON";
import {
  fetchKioskWorkflows,
  fetchVizitinWorkflows,
  updateKioskWorkflow,
  updateVizitinWorkflow,
} from "api/workflow";
import WorkflowDrawer from "components/detailsPage/drawer";
import { TabTable } from "components/detailsPage/tabTable";
import { VizitinRest } from "api/vizitin";

const columns = [
  { title: "Name", dataIndex: "name" },
  { title: "Version", dataIndex: "version", width: "20%" },
  {
    title: "Operation",
    align: "center",
    width: "30%",
    render: (_, row) => {
      return (
        <ViewJSON
          data={row.content}
          title={row.name}
          // filename={`${title}_${row.name}_${row.version}`}
        />
      );
    },
  },
];

class DeviceDetails extends Component {
  constructor(props) {
    super(props);
    this.id = window.location.pathname.split("/")[2];
    this.handleChild = React.createRef();
    this.apiName = window.location.pathname.split("/")[1];
    this.requestParams = this.initialRequestParams();
    this.state = {
      device_type: "bot",
      data: [],
      EmpData: [],
      visible: false,
      tabName: "",
      imageData: [],
      total_entries: 0,
      disable: false,
      radioValues: [],
      status: [],
      rbtn: [
        {
          label: "Published",
          value: "published",
          onClick: this.handleRadioClick,
        },
        { label: "Trained", value: "trained", onClick: this.handleRadioClick },
        {
          label: "UnTrained",
          value: "untrained",
          onClick: this.handleRadioClick,
        },
      ],
    };
  }

  initialRequestParams = () => {
    return {
      search: "",
      perPage: 25,
      page: 1,
    };
  };

  componentDidMount() {
    this.fetchDatas();
  }

  fetchDatas = () => {
    let action =
      this.apiName === "kiosk" ? fetchKioskDetails : fetchVizitinDetails;
    action(this.id)
      .then(({ data }) => {
        this.setState({ data }, this.fetchWorkflow);
      })
      .catch((err) => {
        handleResponse(err);
      });
  };

  fetchWorkflow = () => {
    let action =
      this.apiName === "kiosk" ? fetchKioskWorkflows : fetchVizitinWorkflows;
    action(this.state.data.id)
      .then(({ data }) => {
        this.setState({ workflowData: data }, this.fetchIamgeData);
      })
      .catch((err) => {
        handleResponse(err);
      });
  };

  onClick = (e) => {
    this.setState({ [e]: true });
  };

  onCancel = (e) => {
    this.setState({ [e]: false });
  };

  update = () => {
    const { data } = this.state;
    let id = data.id;
    let workflow_id = this?.state?.selected_id;
    let action =
      this.apiName === "kiosk" ? updateKioskWorkflow : updateVizitinWorkflow;
    action(id, workflow_id)
      .then(({ data }) => {
        this.setState({ selected_id: data.id, current_workflow_id: data.id });
        success("Workflow updated..!!!");
        this.handleSubmitSuccess();
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  tabOnChange = (e) => {
    if (e === "emp" || e === "visit") {
      let action =
        e === "emp" ? fetchEmployeeImage : e === "visit" && fetchVizitinImage;
      action(this.state.data.id, this.requestParams)
        .then(({ data, total_entries }) => {
          this.setState({
            imageData: data,
            total_entries: total_entries,
          });
        })
        .catch((error) => {
          handleResponse(error);
        });
      this.setState({ tabName: e });
    }

    if (e !== this.state.tabName) {
      let btn = this.state.rbtn.map((obj) => {
        return { ...obj, class: "", count: 0 };
      });
      this.setState({ radioValues: [], status: [], rbtn: btn });
    }
  };

  handleChange = (e) => {
    this.setState({ device_type: e.target.value });
  };

  onSelect = (row) => {
    this.setState({ selected_id: row });
  };

  handleSubmitSuccess = () => {
    this.setState({ visible: false });
    setTimeout(() => {
      this.fetchDatas();
    }, 1000);
  };

  handlePageChange = (page) => {
    const { tabName } = this.state;

    this.requestParams.page = page;
    this.setState({ page }, () => this.tabOnChange(tabName));
  };

  handleRadioClick = (e) => {
    const { radioValues } = this.state;
    let arrs = [...radioValues, e];
    this.setState({ radioValues: arrs }, () => this.rbtnhandle(e));
  };

  rbtnhandle = (e) => {
    const { radioValues, rbtn, status, tabName } = this.state;
    let filter_values = [];
    let custom_status = status;
    let count = 1;
    let btn = rbtn.map((obj) => {
      if (radioValues.includes(obj.value)) {
        if (e === obj.value)
          if (obj.count !== 1) {
            count += obj.count || 0;
            filter_values.push(obj.value);
            return { ...obj, class: "rbtn-select", count };
          } else {
            custom_status = status.filter((m) => {
              return m !== obj.value;
            });
            count = 0;
            return { ...obj, class: "", count };
          }
      }
      return obj;
    });
    let values = [...custom_status, ...filter_values];

    this.requestParams.status = values;
    this.setState({ rbtn: btn, status: values });

    if (tabName === "emp") {
      return this.tabOnChange("emp");
    }

    if (tabName === "visit") {
      return this.tabOnChange("visit");
    }
  };

  resetOnClick = (deviceId) => {
    VizitinRest(deviceId)
      .then(() => {
        success("Reset successfully");
        this.tabOnChange("visit");
        this.tabOnChange("emp");
      })
      .catch((err) => {
        handleResponse(err);
      });
  };

  render() {
    const {
      handleChange,
      onClick,
      update,
      onCancel,
      onSubmit,
      handleChild,
      tabOnChange,
      onSelect,
      handlePageChange,
      fetchWorkflow,
      resetOnClick,
      requestParams: { page, perPage },
    } = this;
    const {
      workflowData,
      data,
      device_type,
      visible,
      tabName,
      imageData,
      selected_id,
      total_entries,
      rbtn,
    } = this.state;

    let deviceDatas = [
      {
        title: data?.name,
        name: "Device name",
      },
      {
        title: data?.mac_address,
        name: "Mac Address",
      },
      {
        title: data?.device_type,
        name: "Device type",
      },
      {
        title: data?.bot?.id,
        name: "Bot id",
        label: "Athu Token",
        value: data?.auth_token,
      },
    ];

    const headerProps = {
      data: deviceDatas,
      device_type,
      handleChange,
      resetOnClick,
      id: data.id,
      rbtn: this.apiName === "vizitin",
      border: true,
    };
    const tableProps = {
      data: workflowData,
      buttons: true,
      props: {
        title: "Device Workflow",
        btnProps: [
          { btnLabel: "Add", onClick: onClick, name: "visible" },
          {
            btnLabel: "Make current",
            onClick: update,
            disabled: workflowData && workflowData.length === 0 ? true : false,
          },
        ],
      },
      column: columns,
      onSelect,
      selected_id,
      curr_id: data.workflow_id,
    };

    const tabProps = {
      ...tableProps,
      tabName,
      tabOnChange,
      workflow: true,
      tabs: [
        {
          tab: "Employee",
          key: "emp",
          data: imageData,
          radioProps: {
            rtbn: rbtn,
          },
        },
        {
          tab: "Visitor",
          key: "visit",
          data: imageData,
          radioProps: {
            rtbn: rbtn,
          },
        },
      ],
      total_entries,
      handlePageChange,
      page,
      perPage,
    };

    const DrawerProps = {
      onCancel,
      onSubmit,
      visible,
      handleChild,
      deviceData: data,
      apiName: this.apiName,
      fetchWorkflow,
    };

    return (
      <>
        <AppBar />
        <Header {...headerProps} />
        {this.apiName === "kiosk" ? (
          <TableDatas tableProps={tableProps} />
        ) : (
          <TabTable tabProps={tabProps} />
        )}
        {visible && <WorkflowDrawer {...DrawerProps} />}
      </>
    );
  }
}

export default DeviceDetails;
