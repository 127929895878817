import makeRequest from ".";

function generateParams(params = {}) {
  let time = "";
  if (params.fromTime || params.toTime) {
    time = `from_time=${params.fromTime || ""}&to_time=${params.toTime || ""}`;
  }

  return `${time}&page=${params.page || 1}&per_page=${params.perPage || 25}`;
}

export function fetchOutBoxData(requestParams) {
  return makeRequest({
    uri: `/api/v1/sent_alerts?${generateParams(requestParams)}`,
  });
}

export function showAlerts(id) {
  return makeRequest({ uri: `/api/v1/sent_alerts/${id}` });
}
