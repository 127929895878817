import { apiHost } from "config";
import { headers } from "helpers";
import makeRequest from ".";

function generateParams(params = {}) {
  if (params.sort_order && params.sort_field) {
    return `query=${params.search}&sort_order=${params.sort_order}&sort_field=${
      params.sort_field
    }&page=${params.page || 1}&per_page=${params.perPage}`;
  } else {
    return `query=${params.search}&page=${params.page || 1}&per_page=${
      params.perPage
    }`;
  }
}

export function fetchOutsidersData(requestParams) {
  return makeRequest({
    uri: `/api/v1/visitors?${generateParams(requestParams)}`,
  });
}

export function createOutsider(requestBody) {
  return makeRequest({
    uri: `/api/v1/visitors`,
    method: "POST",
    body: JSON.stringify(requestBody),
  });
}

export function updateOutsider(requestBody, id) {
  return makeRequest({
    uri: `/api/v1/visitors/${id}`,
    method: "PUT",
    body: JSON.stringify(requestBody),
  });
}

export function deleteOutsider(id) {
  return fetch(apiHost + `/api/v1/visitors/${id}`, {
    method: "DELETE",
    headers: headers(),
  }).then(async (response) => {
    if (response.ok) {
      return;
    }
    const errorMessage = [response.status, "Something is not right"];
    throw errorMessage;
  });
}

export function fetchOutsiderPhone(phone) {
  return makeRequest({ uri: `/api/v1/visitors/${phone}` });
}

export function fetchAllVisitorEntries(phone) {
  return makeRequest({ uri: `/api/v1/visits/${phone}/all_entries` });
}

export function fetchAboutVisitorEntries(id) {
  return makeRequest({ uri: `/api/v1/visits/${id}/about` });
}
