import React, { Component } from "react";
import AppBar from "components/AppBar";
import PaginationTable from "components/paginationTables/withAddEdit";
import { Row } from "antd";
import { columns } from "data/employeeEntries";
import { fetchEmployeesEntry, aggregation } from "api/employee";
import moment from "moment";
import { handleResponse } from "components/notifications/handleResponse";
import { setCookie, popCookie, getCookie, debounce } from "helpers";

class EmployeeEntries extends Component {
  constructor(props) {
    super(props);
    this.requestParams = this.initialRequestParams();
    this.currentUrl = this.props.history.location.pathname;
    this.state = {
      data: [],
      formOpen: false,
      total_entries: 0,
    };
  }

  initialRequestParams = () => {
    return {
      perPage: 25,
      page: 1,
      fromTime: moment
        .utc(
          moment()
            .subtract(7, "days")
            .startOf("day")
        )
        .format(),
      toTime: moment.utc(moment().endOf("day")).format(),
    };
  };

  initialFilterState = () => {
    let prevStateValues = getCookie("prevStateValues");

    let phoneFilters = [];
    let nameFilters = [];
    let modeFilters = [];
    if (prevStateValues) {
      let data = JSON.parse(prevStateValues);

      phoneFilters = data.requestParams.phone;
      nameFilters = data.requestParams.name;
      modeFilters = data.requestParams.mode;
    }
    return {
      phoneFilters,
      nameFilters,
      modeFilters,
    };
  };

  componentDidMount() {
    this.fetchApiDatas();
    let scrollY = 0;
    let prevStateValues = popCookie("prevStateValues");
    let usePrevStateValues = popCookie("usePrevStateValues");
    if (usePrevStateValues && prevStateValues) {
      let data = JSON.parse(prevStateValues);

      if (this.currentUrl) {
        this.requestParams = data.requestParams;
        scrollY = data.scrollY;
        this.previousLoad = true;
      } else {
        this.requestParams = this.initialRequestParams();
      }
    } else {
      this.requestParams = this.initialRequestParams();
    }

    setTimeout(function() {
      window.scrollTo({ top: scrollY });
    }, 1000);
  }

  componentWillUnmount = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    let prevStateValues = JSON.stringify({
      scrollY: window.scrollY,
      requestParams: this.requestParams,
      currentUrl: this.currentUrl,
    });
    setCookie("prevStateValues", prevStateValues);
  };

  fetchApiDatas = () => {
    fetchEmployeesEntry(this.requestParams)
      .then(({ data, total_entries }) => {
        this.setState({ data, skeleton: false, total_entries });
      })
      .catch((err) => {
        handleResponse(err);
      });
  };

  pagination = () => {
    const {
      requestParams,
      state: { total_entries },
    } = this;
    return {
      total: total_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };

  onDateChange = (from_time, to_time, loader) => {
    this.requestParams.fromTime = from_time;
    this.requestParams.toTime = to_time;
    this.fetchApiDatas();
    //check
    this.setState({ loader: loader });
  };

  handleTableChange = (pagination) => {
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    this.fetchApiDatas();
  };

  getAggregation = async () => {
    let params = {
      fromTime: this.requestParams.fromTime,
      toTime: this.requestParams.toTime,
    };
    await aggregation(params)
      .then(({ data }) => {
        let entry_mode = data["entry_mode"]["entry_mode"]["buckets"];
        let employee_name = data["employee.name"]["employee.name"]["buckets"];
        let employee_phone = data["employee.phone"]["employee.phone"]["buckets"];

        let modeFilter = entry_mode.map((val) => ({
          label: val.key,
          value: val.key,
        }));

        let nameFilter = employee_name.map((val) => ({
          label: val.key,
          value: val.key,
        }));

        let phoneFilter = employee_phone.map((val) => ({
          label: val.key,
          value: val.key,
        }));

        this.setState({
          phoneFilter,
          nameFilter,
          modeFilter,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  debounceSearch = debounce(() => {
    this.fetchApiDatas();
    this.setState(this.state);
  }, 1000);

  filterChange = (obj) => {
    this.setState({
      loader: obj.loader,
      phoneFilters: obj.phone,
      nameFilters: obj.name,
      modeFilters: obj.mode,
    });
    Object.keys(obj).filter((arr) => {
      return (this.requestParams[arr] = obj[arr]);
    });
    this.debounceSearch();
  };

  render() {
    const {
      data,
      phoneFilter,
      nameFilter,
      modeFilter,
      phoneFilters,
      nameFilters,
      modeFilters,
    } = this.state;
    const { onDateChange, handleTableChange, getAggregation, filterChange } = this;

    let appBarProps = {
      onDateChange,
      dateFilter: true,
      fromTime: moment(this.requestParams.fromTime),
      toTime: moment(this.requestParams.toTime),
      getAggregation,
      filter: true,
      FilterObj: {
        phoneFilter,
        nameFilter,
        modeFilter,
        phoneFilters,
        nameFilters,
        modeFilters,
        filterChange,
      },
    };

    const tableProps = {
      // skeleton,
      title: "Employee Entries",
      subTitle: "Employee entries details here",
      tabColor: "success",
      columns: columns(),
      data,
      pagination: this.pagination(),
      handleTableChange,
      onRowClick: () => {},
      dataValue: "EmployeeLogs",
    };

    return (
      <>
        <AppBar {...appBarProps} />
        <Row>
          <PaginationTable {...tableProps} />
        </Row>
      </>
    );
  }
}

export default EmployeeEntries;
