import React, { Component } from "react";
import AppBar from "components/AppBar";
import PaginationTable from "components/paginationTables/withAddEdit";
import { fetchOutBoxData } from "api/outBox";
import { columns } from "data/outBox";
import { Row } from "antd";
import { handleResponse } from "components/notifications/handleResponse";
import { debounce } from "helpers";
import moment from "moment";
import CRUDSmsalerts from "components/form/smsalerts/CRUD";

class OutBox extends Component {
  constructor(props) {
    super(props);
    this.requestParams = this.initialRequestParams();
    this.state = {
      data: [],
      total_entries: 0,
      rowData: undefined,
      skeleton: true,
      formOpen: false,
    };
  }

  initialRequestParams = () => {
    return {
      perPage: 25,
      page: 1,
      fromTime: moment.utc(moment().startOf("week")).format(),
      toTime: moment.utc(moment().endOf("week")).format(),
    };
  };

  componentDidMount() {
    this.fetchTableData();
  }

  onDateChange = (from_time, to_time) => {
    this.requestParams.fromTime = from_time;
    this.requestParams.toTime = to_time;
    this.fetchTableData();
  };

  debounceSearch = debounce(() => {
    this.fetchTableData();
    this.setState(this.state);
  }, 1000);

  onChange = (name, value) => {
    this.requestParams[name] = value;
    this.setState(this.state);
    this.debounceSearch();
  };

  fetchTableData = () => {
    fetchOutBoxData(this.requestParams)
      .then(({ data, total_entries }) => {
        this.setState({
          data: data,
          total_entries: total_entries,
          skeleton: false,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  pagination = () => {
    const { requestParams, state } = this;
    return {
      total: state.total_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };

  handleTableChange = (pagination, filters) => {
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    this.requestParams.status = filters.status;
    this.fetchTableData();
  };

  handleClose = () => {
    this.setState({
      formOpen: false,
      selectedID: undefined,
    });
  };

  render() {
    const { data, skeleton, formOpen } = this.state;
    const { onChange, onDateChange, handleClose } = this;
    const appBarProps = {
      fromTime: moment(this.requestParams.fromTime),
      toTime: moment(this.requestParams.toTime),
      dateFilter: true,
      onDateChange,
      onChange,
    };
    const tableProps = {
      tabColor: "info",
      title: "OutBox",
      subTitle: "Visitors and Employees send notification details",
      columns: columns(),
      data: data,
      pagination: this.pagination(),
      handleTableChange: this.handleTableChange,
      onRowClick: (row) => {
        this.setState({ formOpen: true, selectedID: row.id });
      },
      handleOnClick: () => {
        this.setState({ formOpen: true });
      },
      skeleton,
    };

    const CRUDProps = {
      id: this.state.selectedID,
      onClose: handleClose,
    };

    return (
      <React.Fragment>
        <AppBar {...appBarProps} />
        <Row>
          <PaginationTable {...tableProps} />
        </Row>
        {formOpen && <CRUDSmsalerts {...CRUDProps} />}
      </React.Fragment>
    );
  }
}
export default OutBox;
