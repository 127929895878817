import React, { Component } from "react";
import DialogForm from "hoc/dialogForm";
import Form from "./Form";
import { createAlertType, updateAlertType } from "api/alertSettings";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import { fetchAlertGroups } from "api/alertGroups";
import { titleize } from "helpers";

class CRUD extends Component {
  constructor(props) {
    super(props);
    this.id = undefined;
    this.state = {
      open: false,
      whatsApp: {
        raised: false,
        resolved: false,
        false_alert: false,
      },
      sms: {
        raised: false,
        resolved: false,
        false_alert: false,
      },
      data: {
        name: "",
        status: "active",
        alias_name: "",
        nature: "normal",
        alert_group: "",
        notification: [],
        whatsapp_content: {
          raised: "",
          resolved: "",
          false_alert: "",
        },
        sms_content: {
          raised: "",
          resolved: "",
          false_alert: "",
        },
        weight: {
          raised: 10,
          resolved: 5,
          false_alert: 2,
        },
        auto_resolve: { label: "Disable", value: "disable" },
        emergency: false,
        description: "",
      },
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    let { data } = this.state;
    if (name.split(".").length > 1) {
      let [parent, child] = name.split(".");
      data[parent][child] = value;
    } else {
      data[name] = value;
    }
    this.setState({ data });
  };

  requestParams = () => {
    const {
      alert_group,
      notification,
      auto_resolve,
      ...restData
    } = this.state.data;
    let notification_id = notification && notification.value;
    let alert_group_id = alert_group && alert_group.value;
    let auto_resolve_value = auto_resolve.value;

    return {
      alert_types: {
        ...restData,
        auto_resolve: auto_resolve_value,
        alert_group_id,
        notification_id,
      },
    };
  };

  onSubmit = () => {
    const { id, props, requestParams } = this;
    props.submitClick();
    const method = id ? updateAlertType : createAlertType;
    method(requestParams(), id)
      .then((data) => {
        success(data.message);
        props.handleSubmitSuccess();
        props.submitClick();
      })
      .catch((error) => {
        handleResponse(error);
        props.submitClick();
      });
  };

  componentDidMount = () => {
    if (this.props.data) {
      const {
        id,
        name,
        nature,
        status,
        alias_name,
        auto_resolve,
        sms_content,
        whatsapp_content,
        weight,
        alert_group,
        notification,
        emergency,
        description,
      } = this.props.data;

      let alert_group_temp = alert_group && {
        value: alert_group.id,
        label: alert_group.name,
      };
      let notification_temp = notification && {
        value: notification.id,
        label: notification.name,
      };
      let auto_resolve_temp = auto_resolve && {
        value: auto_resolve,
        label: titleize(auto_resolve),
      };
      this.id = id;
      this.whatsApp = {
        raised: whatsapp_content.raised ? true : false,
        resolved: whatsapp_content.resolved ? true : false,
        false_alert: whatsapp_content.false_alert ? true : false,
      };
      this.sms = {
        raised: sms_content.raised ? true : false,
        resolved: sms_content.resolved ? true : false,
        false_alert: sms_content.false_alert ? true : false,
      };
      this.setState({
        data: {
          name,
          nature,
          status,
          alias_name,
          auto_resolve: auto_resolve_temp,
          sms_content,
          whatsapp_content,
          weight,
          alert_group: alert_group_temp,
          notification: notification_temp,
          emergency,
          description,
        },
      });
    }

    fetchAlertGroups()
      .then(({ data }) => {
        const alertGroups = data.map((alert_group) => ({
          value: alert_group.id,
          label: alert_group.name,
        }));
        this.setState({ alertGroups });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  render() {
    const {
      handleChange,
      id,
      state: { data, notifications, alertGroups },
      whatsApp,
      sms,
    } = this;

    const formProps = {
      id,
      ...data,
      notifications,
      alertGroups,
      handleChange,
      whatsApp,
      sms,
    };
    return <Form {...formProps} />;
  }
}
export default DialogForm("Alert Types", "38%")(CRUD);
